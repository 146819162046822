import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { PageLinks } from '../Common/Constants';
import AddCustomers from '../Pages/AddCustomers';
import CustomerList from '../Pages/CustomerList';
import Home from '../Pages/Home';
import Login from '../Pages/Login';
import CompanyProfile from './CompanyProfile';
import CompanyTeams from './CompanyTeams';

const Navigates: React.FC = () => {
    return (
        <Routes>
            <Route path='/' element={<Navigate to={PageLinks.Login}/>} />
            <Route path={PageLinks.Login} element={<Login />} />
            <Route path={PageLinks.Home} element={<Home />} />
            <Route path={PageLinks.AddCustomer} element={<AddCustomers />} />
            <Route path={PageLinks.CustomerList} element={<CustomerList />} />
            <Route path={PageLinks.CompanyProfile} element={<CompanyProfile />} />
            <Route path={PageLinks.CompanyTeam} element={<CompanyTeams />} />
        </Routes>
    )
}

export default Navigates