import axios from 'axios';
import { API } from './Constants';
import * as Utilities from "./Utilities";
import toast from 'react-hot-toast';


export namespace ServerAPI {
    export enum APIMethod {
        GET = "GET",
        POST = "POST",
        PUT = "PUT",
        DELETE = "DELETE"
    };

    export const requestLogin = async (userName: string, password: string) => {
        let data: { [k: string]: any } = {};
        data["email"] = userName;
        data["password"] = password;
        return executeAPI(API.EndPoint.Login, APIMethod.POST, false, data);
    }


    export const executeAPI = async (
        endpoint: string,
        method: APIMethod,
        authRequired?: boolean,
        data?: any,
        params?: any,
        suppressError?: boolean
    ) => {

        let headers: { [k: string]: any } = {};
        if (authRequired) {
            const persistAuthString = await Utilities.getAuthString();
            if (persistAuthString) {
                headers["Authorization"] = persistAuthString;
            }
        }

        if (method === APIMethod.POST) {
            headers["Content-Type"] = "multipart/form-data";
        }
        try {
            const response = await axios({
                method: method,
                url: API.BaseUrl + endpoint,
                data: JSON.stringify(data),
                headers: headers,
                params: params,
            });
            console.log(response)
            if (response.status == 201 || response.status == 200) {
                return response.data;
            } else {
                console.log("Error", response);
            }
        } catch (error) {
            //return { success: false, message: "Error Occurred in the Request!" };
            if (axios.isAxiosError(error) && error.response && !suppressError) {
                const errorData = error.response.data;
                if (errorData.errors && errorData.errors.length > 0) {
                    if (errorData.errors[0].value) {
                        toast.error(errorData.errors[0].value);
                    } else {
                        toast.error(errorData.errors[0]);
                    }
                } else {
                    toast.error("Error Occurred in the Request!");
                }
            }
        }
    };
}