export const PageLinks = {
    Login: '/login',
    Home: '/home',
    AddCustomer: '/add_customer',
    PageNotFound: '/404',
    CustomerList: '/customer_list',
    CompanyProfile: '/company_profile',
    CompanyTeam: '/company_team',
}

export const APIResult = {
    SUCCESS: true,
    FAILURE: false
}

export enum Endpoints {
    Login = 'auth/login'
}

export const API = {
    BaseUrl: "https://app.aeonsolutions.com/",
    EndPoint: Endpoints,
    Result: APIResult
}