
import { PageLinks } from "./Constants";

export const getAuthString = () => {
    let basicAuthString = '';
    const loginData = localStorage.getItem('login') === '1' ? true : false;
    let token = localStorage.getItem('token');
    if (token == null) {
        token = "";
    }
    // basicAuthString = (loginData) ? "Basic " + (Buffer.from(token).toString('base64')) : "";
    return token;
}

export const persistLoginDetail = (login: boolean, token: string) => {
    localStorage.setItem('aeon-login', login ? '1' : '0');
    localStorage.setItem('aeon-token', login && token ? token : '');
}

export const getIsLogin = async () => {
    const loginData = await localStorage.getItem('aeon-login') === '1' ? true : false;
    return loginData;
}

export const logout = (navigate:any) => {
    localStorage.removeItem('aeon-login');
    localStorage.removeItem('aeon-token');
    navigate(PageLinks.Login);
}

