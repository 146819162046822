import { BrowserRouter } from 'react-router-dom'
import './App.css';
import Navigates from './Pages/Navigate';
import AppToast from './Pages/Template/AppToast';

function App() {
  return (
    <BrowserRouter>
      <Navigates />
      <AppToast/>
    </BrowserRouter>
  );
}

export default App;
