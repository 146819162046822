import React, { Fragment } from 'react'
import FixedPlugins from '../Components/FixedPlugins'
import NavBar from '../Components/NavBar'
import SideMenu from '../Components/SideMenu'

const Home: React.FC = () => {
    return (
        <Fragment>
            <div className="body-bg min-height-300 bg-primary position-absolute w-100"></div>
            <SideMenu />
            <main className="main-content position-relative border-radius-lg ">

                <NavBar />
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="card  mb-4">
                                        <div className="card-body p-3">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="numbers">
                                                        <p className="text-sm mb-0 text-uppercase font-weight-bold">Today's Money</p>
                                                        <h5 className="font-weight-bolder">
                                                            $53,000
                                                        </h5>
                                                        <p className="mb-0">
                                                            <span className="text-success text-sm font-weight-bolder">+55%</span>
                                                            since yesterday
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-4 text-end">
                                                    <div className="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle">
                                                        <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="card  mb-4">
                                        <div className="card-body p-3">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="numbers">
                                                        <p className="text-sm mb-0 text-uppercase font-weight-bold">Today's Users</p>
                                                        <h5 className="font-weight-bolder">
                                                            2,300
                                                        </h5>
                                                        <p className="mb-0">
                                                            <span className="text-success text-sm font-weight-bolder">+3%</span>
                                                            since last week
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-4 text-end">
                                                    <div className="icon icon-shape bg-gradient-danger shadow-danger text-center rounded-circle">
                                                        <i className="ni ni-world text-lg opacity-10" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="card  mb-4">
                                        <div className="card-body p-3">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="numbers">
                                                        <p className="text-sm mb-0 text-uppercase font-weight-bold">New Clients</p>
                                                        <h5 className="font-weight-bolder">
                                                            +3,462
                                                        </h5>
                                                        <p className="mb-0">
                                                            <span className="text-danger text-sm font-weight-bolder">-2%</span>
                                                            since last quarter
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-4 text-end">
                                                    <div className="icon icon-shape bg-gradient-success shadow-success text-center rounded-circle">
                                                        <i className="ni ni-paper-diploma text-lg opacity-10" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="card  mb-4">
                                        <div className="card-body p-3">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="numbers">
                                                        <p className="text-sm mb-0 text-uppercase font-weight-bold">Sales</p>
                                                        <h5 className="font-weight-bolder">
                                                            $103,430
                                                        </h5>
                                                        <p className="mb-0">
                                                            <span className="text-success text-sm font-weight-bolder">+5%</span> than last month
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-4 text-end">
                                                    <div className="icon icon-shape bg-gradient-warning shadow-warning text-center rounded-circle">
                                                        <i className="ni ni-cart text-lg opacity-10" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7 mb-4 mb-lg-0">
                            <div className="card z-index-2 h-100">
                                <div className="card-header pb-0 pt-3 bg-transparent">
                                    <h6 className="text-capitalize">Sales overview</h6>
                                    <p className="text-sm mb-0">
                                        <i className="fa fa-arrow-up text-success"></i>
                                        <span className="font-weight-bold">4% more</span> in 2021
                                    </p>
                                </div>
                                <div className="card-body p-3">
                                    <div className="chart">
                                        <canvas id="chart-line" className="chart-canvas" height="300"></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="card card-carousel overflow-hidden h-100 p-0">
                                <div id="carouselExampleCaptions" className="carousel slide h-100" data-bs-ride="carousel">
                                    <div className="carousel-inner border-radius-lg h-100">
                                        <div className="carousel-item h-100 active" style={{ backgroundImage: 'url(../../assets/img/img-2.jpg)', backgroundSize: "cover" }}>
                                            <div className="carousel-caption d-none d-md-block bottom-0 text-start start-0 ms-5">
                                                <div className="icon icon-shape icon-sm bg-white text-center border-radius-md mb-3">
                                                    <i className="ni ni-camera-compact text-dark opacity-10"></i>
                                                </div>
                                                <h5 className="text-white mb-1">Get started with Argon</h5>
                                                <p>There’s nothing I really wanted to do in life that I wasn’t able to get good at.</p>
                                            </div>
                                        </div>
                                        <div className="carousel-item h-100" style={{ backgroundImage: 'url(../../assets/img/img-1.jpg)', backgroundSize: "cover" }}>
                                            <div className="carousel-caption d-none d-md-block bottom-0 text-start start-0 ms-5">
                                                <div className="icon icon-shape icon-sm bg-white text-center border-radius-md mb-3">
                                                    <i className="ni ni-bulb-61 text-dark opacity-10"></i>
                                                </div>
                                                <h5 className="text-white mb-1">Faster way to create web pages</h5>
                                                <p>That’s my skill. I’m not really specifically talented at anything except for the ability to learn.</p>
                                            </div>
                                        </div>
                                        <div className="carousel-item h-100" style={{ backgroundImage: 'url(../../assets/img/img-3.jpg)', backgroundSize: "cover" }}>
                                            <div className="carousel-caption d-none d-md-block bottom-0 text-start start-0 ms-5">
                                                <div className="icon icon-shape icon-sm bg-white text-center border-radius-md mb-3">
                                                    <i className="ni ni-trophy text-dark opacity-10"></i>
                                                </div>
                                                <h5 className="text-white mb-1">Share with us your design tips!</h5>
                                                <p>Don’t be afraid to be wrong because you can’t learn anything from a compliment.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="carousel-control-prev w-5 me-3" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next w-5 me-3" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                            <div className="card h-100 ">
                                <div className="card-header">
                                    <h5 className="mb-0 text-capitalize">Team members</h5>
                                </div>
                                <div className="card-body pt-0">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item px-0">
                                            <div className="row align-items-center">
                                                <div className="col-auto d-flex align-items-center">
                                                    <a href="javascript:;" className="avatar">
                                                        <img className="border-radius-lg" alt="Image placeholder" src="../../assets/img/team-1.jpg" />
                                                    </a>
                                                </div>
                                                <div className="col ml-2">
                                                    <h6 className="mb-0">
                                                        <a href="javascript:;">John Michael</a>
                                                    </h6>
                                                    <span className="badge badge-success badge-sm">Online</span>
                                                </div>
                                                <div className="col-auto">
                                                    <button type="button" className="btn btn-outline-primary btn-xs mb-0">Add</button>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item px-0">
                                            <div className="row align-items-center">
                                                <div className="col-auto d-flex align-items-center">
                                                    <a href="javascript:;" className="avatar">
                                                        <img className="border-radius-lg" alt="Image placeholder" src="../../assets/img/team-2.jpg" />
                                                    </a>
                                                </div>
                                                <div className="col ml-2">
                                                    <h6 className="mb-0">
                                                        <a href="javascript:;">Alex Smith</a>
                                                    </h6>
                                                    <span className="badge badge-warning badge-sm">in Meeting</span>
                                                </div>
                                                <div className="col-auto">
                                                    <button type="button" className="btn btn-xs btn-outline-primary mb-0">Add</button>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item px-0">
                                            <div className="row align-items-center">
                                                <div className="col-auto d-flex align-items-center">
                                                    <a href="javascript:;" className="avatar">
                                                        <img className="border-radius-lg" alt="Image placeholder" src="../../assets/img/team-5.jpg" />
                                                    </a>
                                                </div>
                                                <div className="col ml-2">
                                                    <h6 className="mb-0">
                                                        <a href="javascript:;">Samantha Ivy</a>
                                                    </h6>
                                                    <span className="badge badge-danger badge-sm">Offline</span>
                                                </div>
                                                <div className="col-auto">
                                                    <button type="button" className="btn btn-xs btn-outline-primary mb-0">Add</button>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item px-0">
                                            <div className="row align-items-center">
                                                <div className="col-auto d-flex align-items-center">
                                                    <a href="javascript:;" className="avatar">
                                                        <img className="border-radius-lg" alt="Image placeholder" src="../../assets/img/team-4.jpg" />
                                                    </a>
                                                </div>
                                                <div className="col ml-2">
                                                    <h6 className="mb-0">
                                                        <a href="javascript:;">John Michael</a>
                                                    </h6>
                                                    <span className="badge badge-success badge-sm">Online</span>
                                                </div>
                                                <div className="col-auto">
                                                    <button type="button" className="btn btn-xs btn-outline-primary mb-0">Add</button>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                            <div className="card h-100 ">
                                <div className="card-header">
                                    <h5 className="mb-0 text-capitalize">To do list</h5>
                                </div>
                                <div className="card-body pt-0">
                                    <ul className="list-group list-group-flush" data-toggle="checklist">
                                        <li className="checklist-entry list-group-item px-0">
                                            <div className="checklist-item checklist-item-success checklist-item-checked d-flex">
                                                <div className="checklist-info">
                                                    <h6 className="checklist-title mb-0">Call with Dave</h6>
                                                    <small className="text-xs">09:30 AM</small>
                                                </div>
                                                <div className="form-check my-auto ms-auto">
                                                    <input className="form-check-input" type="checkbox" id="customCheck1" checked />
                                                </div>
                                            </div>
                                        </li>
                                        <li className="checklist-entry list-group-item px-0">
                                            <div className="checklist-item checklist-item-warning d-flex">
                                                <div className="checklist-info">
                                                    <h6 className="checklist-title mb-0">Brunch Meeting</h6>
                                                    <small className="text-xs">11:00 AM</small>
                                                </div>
                                                <div className="form-check my-auto ms-auto">
                                                    <input className="form-check-input" type="checkbox" id="customCheck1" />
                                                </div>
                                            </div>
                                        </li>
                                        <li className="checklist-entry list-group-item px-0">
                                            <div className="checklist-item checklist-item-info d-flex">
                                                <div className="checklist-info">
                                                    <h6 className="checklist-title mb-0">Argon Dashboard Launch</h6>
                                                    <small className="text-xs">02:00 PM</small>
                                                </div>
                                                <div className="form-check my-auto ms-auto">
                                                    <input className="form-check-input" type="checkbox" id="customCheck1" />
                                                </div>
                                            </div>
                                        </li>
                                        <li className="checklist-entry list-group-item px-0">
                                            <div className="checklist-item checklist-item-danger checklist-item-checked d-flex">
                                                <div className="checklist-info">
                                                    <h6 className="checklist-title mb-0">Winter Hackaton</h6>
                                                    <small>10:30 AM</small>
                                                </div>
                                                <div className="form-check my-auto ms-auto">
                                                    <input className="form-check-input" type="checkbox" id="customCheck2" checked />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card h-100 ">
                                <div className="card-header">
                                    <h5 className="mb-0 text-capitalize">Progress track</h5>
                                </div>
                                <div className="card-body pt-0">
                                    <ul className="list-group list-group-flush list">
                                        <li className="list-group-item px-0">
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    <a href="javascript:;" className="avatar rounded-circle">
                                                        <img alt="Image placeholder" src="../../assets/img/small-logos/logo-jira.svg" />
                                                    </a>
                                                </div>
                                                <div className="col">
                                                    <h6>React Material Dashboard</h6>
                                                    <div className="progress progress-xs mb-0">
                                                        <div className="progress-bar bg-primary" role="progressbar" aria-valuenow={90} aria-valuemin={0} aria-valuemax={100} style={{ width: "90%" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item px-0">
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    <a href="javascript:;" className="avatar rounded-circle">
                                                        <img alt="Image placeholder" src="../../assets/img/small-logos/logo-asana.svg" />
                                                    </a>
                                                </div>
                                                <div className="col">
                                                    <h6>Argon Design System</h6>
                                                    <div className="progress progress-xs mb-0">
                                                        <div className="progress-bar bg-warning" role="progressbar" aria-valuenow={60} aria-valuemin={0} aria-valuemax={100} style={{ width: "60%" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item px-0">
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    <a href="javascript:;" className="avatar rounded-circle">
                                                        <img alt="Image placeholder" src="../../assets/img/small-logos/logo-spotify.svg" />
                                                    </a>
                                                </div>
                                                <div className="col">
                                                    <h6>VueJs Now UI Kit PRO</h6>
                                                    <div className="progress progress-xs mb-0">
                                                        <div className="progress-bar bg-success" role="progressbar" aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} style={{ width: "100%" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item px-0">
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    <a href="javascript:;" className="avatar rounded-circle">
                                                        <img alt="Image placeholder" src="../../assets/img/small-logos/bootstrap.svg" />
                                                    </a>
                                                </div>
                                                <div className="col">
                                                    <h6>Soft UI Dashboard</h6>
                                                    <div className="progress progress-xs mb-0">
                                                        <div className="progress-bar bg-gradient-primary" role="progressbar" aria-valuenow={72} aria-valuemin={0} aria-valuemax={100} style={{ width: "72%" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 col-lg-5">
                            <div className="card h-100">
                                <div className="card-header d-flex align-items-center border-bottom py-3">
                                    <div className="d-flex align-items-center">
                                        <a href="javascript:;">
                                            <img src="../../assets/img/team-4.jpg" className="avatar" alt="profile-image" />
                                        </a>
                                        <div className="mx-3">
                                            <a href="javascript:;" className="text-dark font-weight-600 text-sm">John Snow</a>
                                            <small className="d-block text-muted">3 days ago</small>
                                        </div>
                                    </div>
                                    <div className="text-end ms-auto">
                                        <button type="button" className="btn btn-xs btn-primary mb-0">
                                            <i className="fas fa-plus pe-2"></i> Follow
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p className="mb-4">
                                        Personal profiles are the perfect way for you to grab their attention and persuade recruiters to continue reading your CV because you’re telling them from the off exactly why they should hire you.
                                    </p>
                                    <img alt="Image placeholder" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/activity-feed.jpg" className="img-fluid border-radius-lg shadow-lg max-height-500" />
                                    <div className="row align-items-center px-2 mt-4 mb-2">
                                        <div className="col-sm-6">
                                            <div className="d-flex">
                                                <div className="d-flex align-items-center">
                                                    <i className="ni ni-like-2 me-1 cursor-pointer opacity-6"></i>
                                                    <span className="text-sm me-3 ">150</span>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <i className="ni ni-chat-round me-1 cursor-pointer opacity-6"></i>
                                                    <span className="text-sm me-3">36</span>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <i className="ni ni-curved-next me-1 cursor-pointer opacity-6"></i>
                                                    <span className="text-sm me-2">12</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 d-none d-sm-block">
                                            <div className="d-flex align-items-center justify-content-sm-end">
                                                <div className="d-flex align-items-center">
                                                    <a href="javascript:;" className="avatar avatar-xs rounded-circle" data-toggle="tooltip" data-original-title="Jessica Rowland">
                                                        <img alt="Image placeholder" src="../../assets/img/team-5.jpg" className="" />
                                                    </a>
                                                    <a href="javascript:;" className="avatar avatar-xs rounded-circle" data-toggle="tooltip" data-original-title="Audrey Love">
                                                        <img alt="Image placeholder" src="../../assets/img/team-2.jpg" className="rounded-circle" />
                                                    </a>
                                                    <a href="javascript:;" className="avatar avatar-xs rounded-circle" data-toggle="tooltip" data-original-title="Michael Lewis">
                                                        <img alt="Image placeholder" src="../../assets/img/team-1.jpg" className="rounded-circle" />
                                                    </a>
                                                </div>
                                                <small className="ps-2 font-weight-bold">and 30+ more</small>
                                            </div>
                                        </div>
                                        <hr className="horizontal dark my-3" />
                                    </div>

                                    <div className="mb-1">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0">
                                                <img alt="Image placeholder" className="avatar rounded-circle" src="../../assets/img/bruce-mars.jpg" />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h6 className="h5 mt-0">Michael Lewis</h6>
                                                <p className="text-sm">I always felt like I could do anything. That’s the main thing people are controlled by! Thoughts- their perception of themselves!</p>
                                                <div className="d-flex">
                                                    <div>
                                                        <i className="ni ni-like-2 me-1 cursor-pointer opacity-6"></i>
                                                    </div>
                                                    <span className="text-sm me-2">3 likes</span>
                                                    <div>
                                                        <i className="ni ni-curved-next me-1 cursor-pointer opacity-6"></i>
                                                    </div>
                                                    <span className="text-sm me-2">2 shares</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex mt-3">
                                            <div className="flex-shrink-0">
                                                <img alt="Image placeholder" className="avatar rounded-circle" src="../../assets/img/team-5.jpg" />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h6 className="h5 mt-0">Jessica Stones</h6>
                                                <p className="text-sm">Society has put up so many boundaries, so many limitations on what’s right and wrong that it’s almost impossible to get a pure thought out. It’s like a little kid, a little boy.</p>
                                                <div className="d-flex">
                                                    <div>
                                                        <i className="ni ni-like-2 me-1 cursor-pointer opacity-6"></i>
                                                    </div>
                                                    <span className="text-sm me-2">10 likes</span>
                                                    <div>
                                                        <i className="ni ni-curved-next me-1 cursor-pointer opacity-6"></i>
                                                    </div>
                                                    <span className="text-sm me-2">1 share</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex mt-4">
                                            <div className="flex-shrink-0">
                                                <img alt="Image placeholder" className="avatar rounded-circle me-3" src="../../assets/img/team-4.jpg" />
                                            </div>
                                            <div className="flex-grow-1 my-auto">
                                                <form>
                                                    <textarea className="form-control" placeholder="Write your comment" rows={1}></textarea>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-7">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table align-items-center mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="text-uppercase text-dark text-xxs font-weight-bolder opacity-7">Project</th>
                                                    <th className="text-uppercase text-dark text-xxs font-weight-bolder opacity-7 ps-2">Budget</th>
                                                    <th className="text-uppercase text-dark text-xxs font-weight-bolder opacity-7 ps-2">Status</th>
                                                    <th className="text-uppercase text-dark text-xxs font-weight-bolder opacity-7 ps-2">Completion</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex px-2">
                                                            <div>
                                                                <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/logos/small-logos/logo-spotify.svg" className="avatar avatar-sm rounded-circle me-2" />
                                                            </div>
                                                            <div className="my-auto">
                                                                <h6 className="mb-0 text-xs">Spotify</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">$2,500</p>
                                                    </td>
                                                    <td>
                                                        <span className="badge badge-dot me-4">
                                                            <i className="bg-info"></i>
                                                            <span className="text-dark text-xs">working</span>
                                                        </span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <div className="d-flex align-items-center">
                                                            <span className="me-2 text-xs">60%</span>
                                                            <div>
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-info" role="progressbar" aria-valuenow={60} aria-valuemin={0} aria-valuemax={100} style={{ width: "60%" }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        <button className="btn btn-link text-dark mb-0">
                                                            <i className="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex px-2">
                                                            <div>
                                                                <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/logos/small-logos/logo-invision.svg" className="avatar avatar-sm rounded-circle me-2" />
                                                            </div>
                                                            <div className="my-auto">
                                                                <h6 className="mb-0 text-xs">Invision</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">$5,000</p>
                                                    </td>
                                                    <td>
                                                        <span className="badge badge-dot me-4">
                                                            <i className="bg-success"></i>
                                                            <span className="text-dark text-xs">done</span>
                                                        </span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <div className="d-flex align-items-center">
                                                            <span className="me-2 text-xs">100%</span>
                                                            <div>
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" role="progressbar" aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} style={{ width: "100%" }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        <button className="btn btn-link text-dark mb-0" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex px-2">
                                                            <div>
                                                                <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/logos/small-logos/logo-jira.svg" className="avatar avatar-sm rounded-circle me-2" />
                                                            </div>
                                                            <div className="my-auto">
                                                                <h6 className="mb-0 text-xs">Jira</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">$3,400</p>
                                                    </td>
                                                    <td>
                                                        <span className="badge badge-dot me-4">
                                                            <i className="bg-danger"></i>
                                                            <span className="text-dark text-xs">canceled</span>
                                                        </span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <div className="d-flex align-items-center">
                                                            <span className="me-2 text-xs">30%</span>
                                                            <div>
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-danger" role="progressbar" aria-valuenow={30} aria-valuemin={0} aria-valuemax={30} style={{ width: "30%" }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        <button className="btn btn-link text-dark mb-0" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex px-2">
                                                            <div>
                                                                <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/logos/small-logos/logo-slack.svg" className="avatar avatar-sm rounded-circle me-2" />
                                                            </div>
                                                            <div className="my-auto">
                                                                <h6 className="mb-0 text-xs">Slack</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">$1,000</p>
                                                    </td>
                                                    <td>
                                                        <span className="badge badge-dot me-4">
                                                            <i className="bg-danger"></i>
                                                            <span className="text-dark text-xs">canceled</span>
                                                        </span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <div className="d-flex align-items-center">
                                                            <span className="me-2 text-xs">0%</span>
                                                            <div>
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={0} style={{ width: "0%" }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        <button className="btn btn-link text-dark mb-0" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex px-2">
                                                            <div>
                                                                <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/logos/small-logos/logo-webdev.svg" className="avatar avatar-sm rounded-circle me-2" />
                                                            </div>
                                                            <div className="my-auto">
                                                                <h6 className="mb-0 text-xs">Webdev</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">$14,000</p>
                                                    </td>
                                                    <td>
                                                        <span className="badge badge-dot me-4">
                                                            <i className="bg-info"></i>
                                                            <span className="text-dark text-xs">working</span>
                                                        </span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <div className="d-flex align-items-center">
                                                            <span className="me-2 text-xs">80%</span>
                                                            <div>
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-info" role="progressbar" aria-valuenow={80} aria-valuemin={0} aria-valuemax={80} style={{ width: "80%" }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        <button className="btn btn-link text-dark mb-0" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex px-2">
                                                            <div>
                                                                <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/logos/small-logos/logo-xd.svg" className="avatar avatar-sm rounded-circle me-2" />
                                                            </div>
                                                            <div className="my-auto">
                                                                <h6 className="mb-0 text-xs">Adobe XD</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">$2,300</p>
                                                    </td>
                                                    <td>
                                                        <span className="badge badge-dot me-4">
                                                            <i className="bg-success"></i>
                                                            <span className="text-dark text-xs">done</span>
                                                        </span>
                                                    </td>
                                                    <td className="align-middle text-center">
                                                        <div className="d-flex align-items-center">
                                                            <span className="me-2 text-xs">100%</span>
                                                            <div>
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" role="progressbar" aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} style={{ width: "100%" }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        <button className="btn btn-link text-dark mb-0" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12 col-md-6 mb-4 mb-md-0">
                                    <div className="card bg-gradient-dark">
                                        <div className="card-body">
                                            <div className="mb-2">
                                                <sup className="text-white">$</sup> <span className="h2 text-white">3,300</span>
                                                <div className="text-white opacity-8 mt-2 text-sm">Your current balance</div>
                                                <div>
                                                    <span className="text-success font-weight-600">+ 15%</span> <span className="text-white opacity-8">($250)</span>
                                                </div>
                                            </div>
                                            <button className="btn btn-sm btn-white mb-0 w-100">Add credit</button>
                                        </div>
                                        <div className="card-footer pt-0">
                                            <div className="row">
                                                <div className="col">
                                                    <small className="text-white opacity-8">Orders: 60%</small>
                                                    <div className="progress progress-xs my-2">
                                                        <div className="progress-bar bg-success" style={{ width: "60%" }}></div>
                                                    </div>
                                                </div>
                                                <div className="col"><small className="text-white opacity-8">Sales: 40%</small>
                                                    <div className="progress progress-xs my-2">
                                                        <div className="progress-bar bg-warning" style={{ width: "40%" }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="card bg-gradient-danger h-100">
                                        <div className="card-body">
                                            <div className="row justify-content-between align-items-center">
                                                <div className="col">
                                                    <img src="../../assets/img/logos/bitcoin.jpg" className="w-30 border-radius-md" alt="Image placeholder" />
                                                </div>
                                                <div className="col-auto">
                                                    <span className="badge badge-lg badge-success">Active</span>
                                                </div>
                                            </div>
                                            <div className="my-4">
                                                <p className="text-white opacity-8 mb-0 text-sm">Address</p>
                                                <div className="h6 text-white cursor-pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Copy Address">0yx8Wkasd8uWpa083Jj81qZhs923K21</div>
                                            </div>
                                            <div className="row mt-5">
                                                <div className="col">
                                                    <p className="text-white opacity-8 mb-0 text-sm">Name</p>
                                                    <span className="d-block h6 text-white">John Snow</span>
                                                </div>
                                                <div className="col ms-auto text-end">
                                                    <div className="btn-groups mt-3">
                                                        <div className="btn rounded-circle btn-sm btn-white mb-0 p-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Receive">
                                                            <i className="ni ni-bold-down p-2"></i>
                                                        </div>
                                                        <div className="btn rounded-circle btn-sm btn-white mb-0 p-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Send">
                                                            <i className="ni ni-bold-up p-2"></i>
                                                        </div>
                                                        <div className="btn rounded-circle btn-sm btn-white mb-0 p-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Swap">
                                                            <i className="ni ni-curved-next p-2"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <div className="card ">
                                    <div className="card-header pb-0 p-3">
                                        <div className="d-flex justify-content-between">
                                            <h6 className="mb-2">Sales by Country</h6>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table align-items-center ">
                                            <tbody>
                                                <tr>
                                                    <td className="w-30">
                                                        <div className="d-flex px-2 py-1 align-items-center">
                                                            <div>
                                                                <img src="../../assets/img/icons/flags/US.png" alt="Country flag" />
                                                            </div>
                                                            <div className="ms-4">
                                                                <p className="text-xs font-weight-bold mb-0">Country:</p>
                                                                <h6 className="text-sm mb-0">United States</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="text-center">
                                                            <p className="text-xs font-weight-bold mb-0">Sales:</p>
                                                            <h6 className="text-sm mb-0">2500</h6>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="text-center">
                                                            <p className="text-xs font-weight-bold mb-0">Value:</p>
                                                            <h6 className="text-sm mb-0">$230,900</h6>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle text-sm">
                                                        <div className="col text-center">
                                                            <p className="text-xs font-weight-bold mb-0">Bounce:</p>
                                                            <h6 className="text-sm mb-0">29.9%</h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="w-30">
                                                        <div className="d-flex px-2 py-1 align-items-center">
                                                            <div>
                                                                <img src="../../assets/img/icons/flags/DE.png" alt="Country flag" />
                                                            </div>
                                                            <div className="ms-4">
                                                                <p className="text-xs font-weight-bold mb-0">Country:</p>
                                                                <h6 className="text-sm mb-0">Germany</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="text-center">
                                                            <p className="text-xs font-weight-bold mb-0">Sales:</p>
                                                            <h6 className="text-sm mb-0">3.900</h6>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="text-center">
                                                            <p className="text-xs font-weight-bold mb-0">Value:</p>
                                                            <h6 className="text-sm mb-0">$440,000</h6>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle text-sm">
                                                        <div className="col text-center">
                                                            <p className="text-xs font-weight-bold mb-0">Bounce:</p>
                                                            <h6 className="text-sm mb-0">40.22%</h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="w-30">
                                                        <div className="d-flex px-2 py-1 align-items-center">
                                                            <div>
                                                                <img src="../../assets/img/icons/flags/GB.png" alt="Country flag" />
                                                            </div>
                                                            <div className="ms-4">
                                                                <p className="text-xs font-weight-bold mb-0">Country:</p>
                                                                <h6 className="text-sm mb-0">Great Britain</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="text-center">
                                                            <p className="text-xs font-weight-bold mb-0">Sales:</p>
                                                            <h6 className="text-sm mb-0">1.400</h6>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="text-center">
                                                            <p className="text-xs font-weight-bold mb-0">Value:</p>
                                                            <h6 className="text-sm mb-0">$190,700</h6>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle text-sm">
                                                        <div className="col text-center">
                                                            <p className="text-xs font-weight-bold mb-0">Bounce:</p>
                                                            <h6 className="text-sm mb-0">23.44%</h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="w-30">
                                                        <div className="d-flex px-2 py-1 align-items-center">
                                                            <div>
                                                                <img src="../../assets/img/icons/flags/BR.png" alt="Country flag" />
                                                            </div>
                                                            <div className="ms-4">
                                                                <p className="text-xs font-weight-bold mb-0">Country:</p>
                                                                <h6 className="text-sm mb-0">Brasil</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="text-center">
                                                            <p className="text-xs font-weight-bold mb-0">Sales:</p>
                                                            <h6 className="text-sm mb-0">562</h6>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="text-center">
                                                            <p className="text-xs font-weight-bold mb-0">Value:</p>
                                                            <h6 className="text-sm mb-0">$143,960</h6>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle text-sm">
                                                        <div className="col text-center">
                                                            <p className="text-xs font-weight-bold mb-0">Bounce:</p>
                                                            <h6 className="text-sm mb-0">32.14%</h6>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 col-md-8 mb-4 mb-md-0">
                            <div className="card">
                                <div className="table-responsive">
                                    <table className="table align-items-center mb-0">
                                        <thead>
                                            <tr>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Author</th>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Function</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Technology</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Employed</th>
                                                <th className="text-secondary opacity-7"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="d-flex px-2 py-1">
                                                        <div>
                                                            <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-2.jpg" className="avatar avatar-sm me-3" />
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <h6 className="mb-0 text-xs">John Michael</h6>
                                                            <p className="text-xs text-secondary mb-0"><a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="5c363334321c3f2e393d28352a3971283531723f3331">[email&#160;protected]</a></p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="text-xs font-weight-bold mb-0">Manager</p>
                                                    <p className="text-xs text-secondary mb-0">Organization</p>
                                                </td>
                                                <td className="align-middle text-center text-sm">
                                                    <span className="badge badge-sm badge-success">Online</span>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <span className="text-secondary text-xs font-weight-bold">23/04/18</span>
                                                </td>
                                                <td className="align-middle">
                                                    <a href="javascript:;" className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                                        Edit
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-flex px-2 py-1">
                                                        <div>
                                                            <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-3.jpg" className="avatar avatar-sm me-3" />
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <h6 className="mb-0 text-xs">Alexa Liras</h6>
                                                            <p className="text-xs text-secondary mb-0"><a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="1f7e737a677e5f7c6d7a7e6b76697a326b7672317c7072">[email&#160;protected]</a></p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="text-xs font-weight-bold mb-0">Programator</p>
                                                    <p className="text-xs text-secondary mb-0">Developer</p>
                                                </td>
                                                <td className="align-middle text-center text-sm">
                                                    <span className="badge badge-sm badge-secondary">Offline</span>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <span className="text-secondary text-xs font-weight-bold">11/01/19</span>
                                                </td>
                                                <td className="align-middle">
                                                    <a href="javascript:;" className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                                        Edit
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-flex px-2 py-1">
                                                        <div>
                                                            <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-4.jpg" className="avatar avatar-sm me-3" />
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <h6 className="mb-0 text-xs">Laurent Perrier</h6>
                                                            <p className="text-xs text-secondary mb-0"><a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="6509041017000b112506170004110c130048110c084b060a08">[email&#160;protected]</a></p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="text-xs font-weight-bold mb-0">Executive</p>
                                                    <p className="text-xs text-secondary mb-0">Projects</p>
                                                </td>
                                                <td className="align-middle text-center text-sm">
                                                    <span className="badge badge-sm badge-success">Online</span>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <span className="text-secondary text-xs font-weight-bold">19/09/17</span>
                                                </td>
                                                <td className="align-middle">
                                                    <a href="javascript:;" className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                                        Edit
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-flex px-2 py-1">
                                                        <div>
                                                            <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-3.jpg" className="avatar avatar-sm me-3" />
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <h6 className="mb-0 text-xs">Michael Levi</h6>
                                                            <p className="text-xs text-secondary mb-0"><a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="4924202a21282c25092a3b2c283d203f2c643d2024672a2624">[email&#160;protected]</a></p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="text-xs font-weight-bold mb-0">Programator</p>
                                                    <p className="text-xs text-secondary mb-0">Developer</p>
                                                </td>
                                                <td className="align-middle text-center text-sm">
                                                    <span className="badge badge-sm badge-success">Online</span>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <span className="text-secondary text-xs font-weight-bold">24/12/08</span>
                                                </td>
                                                <td className="align-middle">
                                                    <a href="javascript:;" className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                                        Edit
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="d-flex px-2 py-1">
                                                        <div>
                                                            <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-2.jpg" className="avatar avatar-sm me-3" />
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <h6 className="mb-0 text-xs">Richard Gran</h6>
                                                            <p className="text-xs text-secondary mb-0"><a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="285a414b40495a4c684b5a4d495c415e4d055c4145064b4745">[email&#160;protected]</a></p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="text-xs font-weight-bold mb-0">Manager</p>
                                                    <p className="text-xs text-secondary mb-0">Executive</p>
                                                </td>
                                                <td className="align-middle text-center text-sm">
                                                    <span className="badge badge-sm badge-secondary">Offline</span>
                                                </td>
                                                <td className="align-middle text-center">
                                                    <span className="text-secondary text-xs font-weight-bold">04/10/21</span>
                                                </td>
                                                <td className="align-middle">
                                                    <a href="javascript:;" className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                                        Edit
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-header pb-0 p-3">
                                    <h6 className="mb-0">Categories</h6>
                                </div>
                                <div className="card-body p-3">
                                    <ul className="list-group">
                                        <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                            <div className="d-flex align-items-center">
                                                <div className="icon icon-shape icon-sm me-3 bg-gradient-dark shadow text-center">
                                                    <i className="ni ni-mobile-button text-white opacity-10"></i>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <h6 className="mb-1 text-dark text-sm">Devices</h6>
                                                    <span className="text-xs">250 in stock, <span className="font-weight-bold">346+ sold</span></span>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <button className="btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right my-auto"><i className="ni ni-bold-right" aria-hidden="true"></i></button>
                                            </div>
                                        </li>
                                        <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                            <div className="d-flex align-items-center">
                                                <div className="icon icon-shape icon-sm me-3 bg-gradient-dark shadow text-center">
                                                    <i className="ni ni-tag text-white opacity-10"></i>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <h6 className="mb-1 text-dark text-sm">Tickets</h6>
                                                    <span className="text-xs">123 closed, <span className="font-weight-bold">15 open</span></span>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <button className="btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right my-auto"><i className="ni ni-bold-right" aria-hidden="true"></i></button>
                                            </div>
                                        </li>
                                        <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                            <div className="d-flex align-items-center">
                                                <div className="icon icon-shape icon-sm me-3 bg-gradient-dark shadow text-center">
                                                    <i className="ni ni-box-2 text-white opacity-10"></i>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <h6 className="mb-1 text-dark text-sm">Error logs</h6>
                                                    <span className="text-xs">1 is active, <span className="font-weight-bold">40 closed</span></span>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <button className="btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right my-auto"><i className="ni ni-bold-right" aria-hidden="true"></i></button>
                                            </div>
                                        </li>
                                        <li className="list-group-item border-0 d-flex justify-content-between ps-0 border-radius-lg">
                                            <div className="d-flex align-items-center">
                                                <div className="icon icon-shape icon-sm me-3 bg-gradient-dark shadow text-center">
                                                    <i className="ni ni-satisfied text-white opacity-10"></i>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <h6 className="mb-1 text-dark text-sm">Happy users</h6>
                                                    <span className="text-xs font-weight-bold">+ 430</span>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <button className="btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right my-auto"><i className="ni ni-bold-right" aria-hidden="true"></i></button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className="footer pt-3  ">
                        <div className="container-fluid">
                            <div className="row align-items-center justify-content-lg-between">
                                <div className="col-lg-6 mb-lg-0 mb-4">
                                    <div className="copyright text-center text-sm text-muted text-lg-start">
                                        © <script data-cfasync="false" src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script><script>
                                            document.write(new Date().getFullYear())
                                        </script>,
                                        made with <i className="fa fa-heart"></i> by
                                        <a href="https://www.creative-tim.com" className="font-weight-bold" target="_blank">Creative Tim</a>
                                        for a better web.
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                                        <li className="nav-item">
                                            <a href="https://www.creative-tim.com" className="nav-link text-muted" target="_blank">Creative Tim</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="https://www.creative-tim.com/presentation" className="nav-link text-muted" target="_blank">About Us</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="https://www.creative-tim.com/blog" className="nav-link text-muted" target="_blank">Blog</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="https://www.creative-tim.com/license" className="nav-link pe-0 text-muted" target="_blank">License</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
                <FixedPlugins />
            </main>
        </Fragment>
    )
}

export default Home