import React from 'react'

const FixedPlugins: React.FC = () => {
    return (
        <div className="fixed-plugin">
            <a className="fixed-plugin-button text-dark position-fixed px-3 py-2">
                <i className="fa fa-cog py-2"> </i>
            </a>
            <div className="card shadow-lg">
                <div className="card-header pb-0 pt-3 bg-transparent ">
                    <div className="float-start">
                        <h5 className="mt-3 mb-0">Argon Configurator</h5>
                        <p>See our dashboard options.</p>
                    </div>
                    <div className="float-end mt-4">
                        <button className="btn btn-link text-dark p-0 fixed-plugin-close-button">
                            <i className="fa fa-close"></i>
                        </button>
                    </div>

                </div>
                <hr className="horizontal dark my-1" />
                <div className="card-body pt-sm-3 pt-0 overflow-auto">

                    <div>
                        <h6 className="mb-0">Sidebar Colors</h6>
                    </div>
                    <a href="javascript:void(0)" className="switch-trigger background-color">
                        <div className="badge-colors my-2 text-start">
                            <span className="badge filter bg-gradient-primary active" data-color="primary"></span>
                            <span className="badge filter bg-gradient-dark" data-color="dark"></span>
                            <span className="badge filter bg-gradient-info" data-color="info"></span>
                            <span className="badge filter bg-gradient-success" data-color="success"></span>
                            <span className="badge filter bg-gradient-warning" data-color="warning"></span>
                            <span className="badge filter bg-gradient-danger" data-color="danger"></span>
                        </div>
                    </a>

                    <div className="mt-3">
                        <h6 className="mb-0">Sidenav Type</h6>
                        <p className="text-sm">Choose between 2 different sidenav types.</p>
                    </div>
                    <div className="d-flex">
                        <button className="btn bg-gradient-primary w-100 px-3 mb-2 active me-2" data-className="bg-white">White</button>
                        <button className="btn bg-gradient-primary w-100 px-3 mb-2" data-className="bg-default">Dark</button>
                    </div>
                    <p className="text-sm d-xl-none d-block mt-2">You can change the sidenav type just on desktop view.</p>

                    <div className="d-flex my-3">
                        <h6 className="mb-0">Navbar Fixed</h6>
                        <div className="form-check form-switch ps-0 ms-auto my-auto">
                            <input className="form-check-input mt-1 ms-auto" type="checkbox" id="navbarFixed" />
                        </div>
                    </div>
                    <hr className="horizontal dark mb-1" />
                    <div className="d-flex my-4">
                        <h6 className="mb-0">Sidenav Mini</h6>
                        <div className="form-check form-switch ps-0 ms-auto my-auto">
                            <input className="form-check-input mt-1 ms-auto" type="checkbox" id="navbarMinimize" />
                        </div>
                    </div>
                    <hr className="horizontal dark my-sm-4" />
                    <div className="mt-2 mb-5 d-flex">
                        <h6 className="mb-0">Light / Dark</h6>
                        <div className="form-check form-switch ps-0 ms-auto my-auto">
                            <input className="form-check-input mt-1 ms-auto" type="checkbox" id="dark-version" />
                        </div>
                    </div>
                    <a className="btn btn-primary w-100" href="https://www.creative-tim.com/product/argon-dashboard-pro">Buy now</a>
                    <a className="btn btn-dark w-100" href="https://www.creative-tim.com/product/argon-dashboard">Free demo</a>
                    <a className="btn btn-outline-dark w-100" href="https://www.creative-tim.com/learning-lab/bootstrap/overview/argon-dashboard">View documentation</a>
                    <div className="w-100 text-center">
                        <a className="github-button" href="https://github.com/creativetimofficial/ct-argon-dashboard-pro" data-icon="octicon-star" data-size="large" data-show-count="true" aria-label="Star creativetimofficial/argon-dashboard on GitHub">Star</a>
                        <h6 className="mt-3">Thank you for sharing!</h6>
                        <a href="https://twitter.com/intent/tweet?text=Check%20Argon%20Dashboard%20PRO%20made%20by%20%40CreativeTim%20%23webdesign%20%23dashboard%20%23bootstrap5&amp;url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fargon-dashboard-pro" className="btn btn-dark mb-0 me-2" target="_blank">
                            <i className="fab fa-twitter me-1" aria-hidden="true"></i> Tweet
                        </a>
                        <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/argon-dashboard-pro" className="btn btn-dark mb-0 me-2" target="_blank">
                            <i className="fab fa-facebook-square me-1" aria-hidden="true"></i> Share
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FixedPlugins