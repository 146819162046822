import React, { Fragment } from 'react'
import FixedPlugins from '../Components/FixedPlugins'
import NavBar from '../Components/NavBar'
import SideMenu from '../Components/SideMenu'

const AddCustomers: React.FC = () => {
    return (
        <Fragment>
            <div className="body-bg min-height-300 bg-primary position-absolute w-100"></div>
            <SideMenu />
            <main className="main-content position-relative border-radius-lg ">
                <NavBar />
                <div className="container-fluid py-4">
                    <div className="row mb-5">
                        <div className="col-12">
                            <div className="multisteps-form mb-5">

                                <div className="row">
                                    <div className="col-12 col-lg-8 mx-auto my-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="multisteps-form__progress">
                                                    <button className="multisteps-form__progress-btn js-active" type="button" title="User Info">
                                                        <span>User Info</span>
                                                    </button>
                                                    <button className="multisteps-form__progress-btn" type="button" title="Address">Address</button>
                                                    <button className="multisteps-form__progress-btn" type="button" title="Socials">Socials</button>
                                                    <button className="multisteps-form__progress-btn" type="button" title="Profile">Profile</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-lg-8 m-auto">
                                        <form className="multisteps-form__form mb-8">

                                            <div className="card multisteps-form__panel p-3 border-radius-xl bg-white js-active" data-animation="FadeIn">
                                                <h5 className="font-weight-bolder mb-0">About me</h5>
                                                <p className="mb-0 text-sm">Mandatory informations</p>
                                                <div className="multisteps-form__content">
                                                    <div className="row mt-3">
                                                        <div className="col-12 col-sm-6">
                                                            <label>First Name</label>
                                                            <input className="multisteps-form__input form-control" type="text" placeholder="eg. Michael" />
                                                        </div>
                                                        <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                                            <label>Last Name</label>
                                                            <input className="multisteps-form__input form-control" type="text" placeholder="eg. Prior" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-12 col-sm-6">
                                                            <label>Company</label>
                                                            <input className="multisteps-form__input form-control" type="text" placeholder="eg. Creative Tim" />
                                                        </div>
                                                        <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                                            <label>Email Address</label>
                                                            <input className="multisteps-form__input form-control" type="email" placeholder="eg. argon@dashboard.com" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-12 col-sm-6">
                                                            <label>Password</label>
                                                            <input className="multisteps-form__input form-control" type="password" placeholder="******" />
                                                        </div>
                                                        <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                                                            <label>Repeat Password</label>
                                                            <input className="multisteps-form__input form-control" type="password" placeholder="******" />
                                                        </div>
                                                    </div>
                                                    <div className="button-row d-flex mt-4">
                                                        <button className="btn bg-gradient-dark ms-auto mb-0 js-btn-next" type="button" title="Next">Next</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn">
                                                <h5 className="font-weight-bolder">Address</h5>
                                                <div className="multisteps-form__content">
                                                    <div className="row mt-3">
                                                        <div className="col">
                                                            <label>Address 1</label>
                                                            <input className="multisteps-form__input form-control" type="text" placeholder="eg. Street 111" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col">
                                                            <label>Address 2</label>
                                                            <input className="multisteps-form__input form-control" type="text" placeholder="eg. Street 221" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-12 col-sm-6">
                                                            <label>City</label>
                                                            <input className="multisteps-form__input form-control" type="text" placeholder="eg. Tokyo" />
                                                        </div>
                                                        <div className="col-6 col-sm-3 mt-3 mt-sm-0">
                                                            <label>State</label>
                                                            <select className="multisteps-form__select form-control">
                                                                <option value="1">State 1</option>
                                                                <option value="2">State 2</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-6 col-sm-3 mt-3 mt-sm-0">
                                                            <label>Zip</label>
                                                            <input className="multisteps-form__input form-control" type="text" placeholder="7 letters" />
                                                        </div>
                                                    </div>
                                                    <div className="button-row d-flex mt-4">
                                                        <button className="btn bg-gradient-light mb-0 js-btn-prev" type="button" title="Prev">Prev</button>
                                                        <button className="btn bg-gradient-dark ms-auto mb-0 js-btn-next" type="button" title="Next">Next</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn">
                                                <h5 className="font-weight-bolder">Socials</h5>
                                                <div className="multisteps-form__content">
                                                    <div className="row mt-3">
                                                        <div className="col-12">
                                                            <label>Twitter Handle</label>
                                                            <input className="multisteps-form__input form-control" type="text" placeholder="@argon" />
                                                        </div>
                                                        <div className="col-12 mt-3">
                                                            <label>Facebook Account</label>
                                                            <input className="multisteps-form__input form-control" type="text" placeholder="https://..." />
                                                        </div>
                                                        <div className="col-12 mt-3">
                                                            <label>Instagram Account</label>
                                                            <input className="multisteps-form__input form-control" type="text" placeholder="https://..." />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="button-row d-flex mt-4 col-12">
                                                            <button className="btn bg-gradient-light mb-0 js-btn-prev" type="button" title="Prev">Prev</button>
                                                            <button className="btn bg-gradient-dark ms-auto mb-0 js-btn-next" type="button" title="Next">Next</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card multisteps-form__panel p-3 border-radius-xl bg-white h-100" data-animation="FadeIn">
                                                <h5 className="font-weight-bolder">Profile</h5>
                                                <div className="multisteps-form__content mt-3">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <label>Public Email</label>
                                                            <input className="multisteps-form__input form-control" type="text" placeholder="Use an address you don't use frequently." />
                                                        </div>
                                                        <div className="col-12 mt-3">
                                                            <label>Bio</label>
                                                            <textarea className="multisteps-form__textarea form-control" rows={5} placeholder="Say a few words about who you are or what you're working on."></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="button-row d-flex mt-4">
                                                        <button className="btn bg-gradient-light mb-0 js-btn-prev" type="button" title="Prev">Prev</button>
                                                        <button className="btn bg-gradient-dark ms-auto mb-0" type="button" title="Send">Send</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <footer className="footer pt-3  ">
                        <div className="container-fluid">
                            <div className="row align-items-center justify-content-lg-between">
                                <div className="col-lg-6 mb-lg-0 mb-4">
                                    <div className="copyright text-center text-sm text-muted text-lg-start">
                                        ©
                                        <script>
                                            document.write(new Date().getFullYear())
                                        </script>, made with <i className="fa fa-heart"></i> by
                                        <a href="https://www.creative-tim.com" className="font-weight-bold" target="_blank">Creative Tim</a> for a better web.
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                                        <li className="nav-item">
                                            <a href="https://www.creative-tim.com" className="nav-link text-muted" target="_blank">Creative Tim</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="https://www.creative-tim.com/presentation" className="nav-link text-muted" target="_blank">About Us</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="https://www.creative-tim.com/blog" className="nav-link text-muted" target="_blank">Blog</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="https://www.creative-tim.com/license" className="nav-link pe-0 text-muted" target="_blank">License</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </footer> */}
                </div>
                <FixedPlugins />
            </main>

        </Fragment>
    )
}

export default AddCustomers