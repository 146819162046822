import React, { Fragment } from 'react'
import FixedPlugins from '../Components/FixedPlugins'
import NavBar from '../Components/NavBar'
import SideMenu from '../Components/SideMenu'

const CompanyTeams: React.FC = () => {
    return (
        <Fragment>
            <div className="body-bg min-height-300 bg-primary position-absolute w-100"></div>
            <SideMenu />
            <main className="main-content position-relative border-radius-lg ">
                <NavBar />
                <div className="card shadow-lg mx-4 card-profile-bottom">
                    <div className="card-body p-3">
                        <div className="row gx-4">
                            <div className="col-auto">
                                <div className="avatar avatar-xl position-relative">
                                    <img src="../../../assets/img/team-1.jpg" alt="profile_image" className="w-100 border-radius-lg shadow-sm" />
                                </div>
                            </div>
                            <div className="col-auto my-auto">
                                <div className="h-100">
                                    <h5 className="mb-1">
                                        Sayo Kravits
                                    </h5>
                                    <p className="mb-0 font-weight-bold text-sm">
                                        Public Relations
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
                                <div className="nav-wrapper position-relative end-0">
                                    <ul className="nav nav-pills nav-fill p-1" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link mb-0 px-0 py-1 active d-flex align-items-center justify-content-center " data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true">
                                                <i className="ni ni-app"></i>
                                                <span className="ms-2">App</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link mb-0 px-0 py-1 d-flex align-items-center justify-content-center  active " data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="false">
                                                <i className="ni ni-email-83"></i>
                                                <span className="ms-2">Messages</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link mb-0 px-0 py-1 d-flex align-items-center justify-content-center " data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="false">
                                                <i className="ni ni-settings-gear-65"></i>
                                                <span className="ms-2">Settings</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card overflow-scroll">
                                <div className="card-body d-flex">
                                    <div className="col-lg-1 col-md-2 col-sm-3 col-4 text-center">
                                        <a href="javascript:;" className="avatar avatar-lg border-1 rounded-circle bg-gradient-primary">
                                            <i className="fas fa-plus text-white"></i>
                                        </a>
                                        <p className="mb-0 text-sm" style={{ marginTop: "6px" }}>Add story</p>
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-3 col-4 text-center">
                                        <a href="javascript:;" className="avatar avatar-lg rounded-circle border border-primary">
                                            <img alt="Image placeholder" className="p-1" src="../../../assets/img/team-1.jpg" />
                                        </a>
                                        <p className="mb-0 text-sm">Abbie W</p>
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-3 col-4 text-center">
                                        <a href="javascript:;" className="avatar avatar-lg rounded-circle border border-primary">
                                            <img alt="Image placeholder" className="p-1" src="../../../assets/img/team-2.jpg" />
                                        </a>
                                        <p className="mb-0 text-sm">Boris U</p>
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-3 col-4 text-center">
                                        <a href="javascript:;" className="avatar avatar-lg rounded-circle border border-primary">
                                            <img alt="Image placeholder" className="p-1" src="../../../assets/img/team-3.jpg" />
                                        </a>
                                        <p className="mb-0 text-sm">Kay R</p>
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-3 col-4 text-center">
                                        <a href="javascript:;" className="avatar avatar-lg rounded-circle border border-primary">
                                            <img alt="Image placeholder" className="p-1" src="../../../assets/img/team-4.jpg" />
                                        </a>
                                        <p className="mb-0 text-sm">Tom M</p>
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-3 col-4 text-center">
                                        <a href="javascript:;" className="avatar avatar-lg rounded-circle border border-primary">
                                            <img alt="Image placeholder" className="p-1" src="../../../assets/img/team-5.jpg" />
                                        </a>
                                        <p className="mb-0 text-sm">Nicole N</p>
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-3 col-4 text-center">
                                        <a href="javascript:;" className="avatar avatar-lg rounded-circle border border-primary">
                                            <img alt="Image placeholder" className="p-1" src="../../../assets/img/marie.jpg" />
                                        </a>
                                        <p className="mb-0 text-sm">Marie P</p>
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-3 col-4 text-center">
                                        <a href="javascript:;" className="avatar avatar-lg rounded-circle border border-primary">
                                            <img alt="Image placeholder" className="p-1" src="../../../assets/img/bruce-mars.jpg" />
                                        </a>
                                        <p className="mb-0 text-sm">Bruce M</p>
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-3 col-4 text-center">
                                        <a href="javascript:;" className="avatar avatar-lg rounded-circle border border-secondary">
                                            <img alt="Image placeholder" className="p-1" src="../../../assets/img/ivana-squares.jpg" />
                                        </a>
                                        <p className="mb-0 text-sm">Sandra A</p>
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-3 col-4 text-center">
                                        <a href="javascript:;" className="avatar avatar-lg rounded-circle border border-secondary">
                                            <img alt="Image placeholder" className="p-1" src="../../../assets/img/kal-visuals-square.jpg" />
                                        </a>
                                        <p className="mb-0 text-sm">Katty L</p>
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-3 col-4 text-center">
                                        <a href="javascript:;" className="avatar avatar-lg rounded-circle border border-secondary">
                                            <img alt="Image placeholder" className="p-1" src="../../../assets/img/ivana-square.jpg" />
                                        </a>
                                        <p className="mb-0 text-sm">Emma O</p>
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-3 col-4 text-center">
                                        <a href="javascript:;" className="avatar avatar-lg rounded-circle border border-secondary">
                                            <img alt="Image placeholder" className="p-1" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/team-9.jpg" />
                                        </a>
                                        <p className="mb-0 text-sm">Tao G</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 col-lg-6">
                            <div className="card h-100">
                                <div className="card-header d-flex align-items-center border-bottom py-3">
                                    <div className="d-flex align-items-center">
                                        <a href="javascript:;">
                                            <img src="../../../assets/img/team-4.jpg" className="avatar" alt="profile-image" />
                                        </a>
                                        <div className="mx-3">
                                            <a href="javascript:;" className="text-dark font-weight-600 text-sm">John Snow</a>
                                            <small className="d-block text-muted">3 days ago</small>
                                        </div>
                                    </div>
                                    <div className="text-end ms-auto">
                                        <button type="button" className="btn btn-xs btn-primary mb-0">
                                            <i className="fas fa-plus pe-2"></i> Follow
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p className="mb-4">
                                        Personal profiles are the perfect way for you to grab their attention and persuade recruiters to continue reading your CV because you’re telling them from the off exactly why they should hire you.
                                    </p>
                                    <img alt="Image placeholder" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/activity-feed.jpg" className="img-fluid border-radius-lg shadow-lg max-height-500" />
                                    <div className="row align-items-center px-2 mt-4 mb-2">
                                        <div className="col-sm-6">
                                            <div className="d-flex">
                                                <div className="d-flex align-items-center">
                                                    <i className="ni ni-like-2 me-1 cursor-pointer opacity-6"></i>
                                                    <span className="text-sm me-3 ">150</span>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <i className="ni ni-chat-round me-1 cursor-pointer opacity-6"></i>
                                                    <span className="text-sm me-3">36</span>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <i className="ni ni-curved-next me-1 cursor-pointer opacity-6"></i>
                                                    <span className="text-sm me-2">12</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 d-none d-sm-block">
                                            <div className="d-flex align-items-center justify-content-sm-end">
                                                <div className="d-flex align-items-center">
                                                    <a href="javascript:;" className="avatar avatar-xs rounded-circle" data-toggle="tooltip" data-original-title="Jessica Rowland">
                                                        <img alt="Image placeholder" src="../../../assets/img/team-5.jpg" className="" />
                                                    </a>
                                                    <a href="javascript:;" className="avatar avatar-xs rounded-circle" data-toggle="tooltip" data-original-title="Audrey Love">
                                                        <img alt="Image placeholder" src="../../../assets/img/team-2.jpg" className="rounded-circle" />
                                                    </a>
                                                    <a href="javascript:;" className="avatar avatar-xs rounded-circle" data-toggle="tooltip" data-original-title="Michael Lewis">
                                                        <img alt="Image placeholder" src="../../../assets/img/team-1.jpg" className="rounded-circle" />
                                                    </a>
                                                </div>
                                                <small className="ps-2 font-weight-bold">and 30+ more</small>
                                            </div>
                                        </div>
                                        <hr className="horizontal dark my-3" />
                                    </div>

                                    <div className="mb-1">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0">
                                                <img alt="Image placeholder" className="avatar rounded-circle" src="../../../assets/img/bruce-mars.jpg" />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h6 className="h5 mt-0">Michael Lewis</h6>
                                                <p className="text-sm">I always felt like I could do anything. That’s the main thing people are controlled by! Thoughts- their perception of themselves!</p>
                                                <div className="d-flex">
                                                    <div>
                                                        <i className="ni ni-like-2 me-1 cursor-pointer opacity-6"></i>
                                                    </div>
                                                    <span className="text-sm me-2">3 likes</span>
                                                    <div>
                                                        <i className="ni ni-curved-next me-1 cursor-pointer opacity-6"></i>
                                                    </div>
                                                    <span className="text-sm me-2">2 shares</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex mt-3">
                                            <div className="flex-shrink-0">
                                                <img alt="Image placeholder" className="avatar rounded-circle" src="../../../assets/img/team-5.jpg" />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h6 className="h5 mt-0">Jessica Stones</h6>
                                                <p className="text-sm">Society has put up so many boundaries, so many limitations on what’s right and wrong that it’s almost impossible to get a pure thought out. It’s like a little kid, a little boy.</p>
                                                <div className="d-flex">
                                                    <div>
                                                        <i className="ni ni-like-2 me-1 cursor-pointer opacity-6"></i>
                                                    </div>
                                                    <span className="text-sm me-2">10 likes</span>
                                                    <div>
                                                        <i className="ni ni-curved-next me-1 cursor-pointer opacity-6"></i>
                                                    </div>
                                                    <span className="text-sm me-2">1 share</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex mt-4">
                                            <div className="flex-shrink-0">
                                                <img alt="Image placeholder" className="avatar rounded-circle me-3" src="../../../assets/img/team-4.jpg" />
                                            </div>
                                            <div className="flex-grow-1 my-auto">
                                                <form>
                                                    <textarea className="form-control" placeholder="Write your comment" rows={1}></textarea>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="card mb-3 mt-lg-0 mt-4">
                                <div className="card-body pb-0">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-9">
                                            <h5 className="mb-1">
                                                <a href="javascript:;">Digital Marketing</a>
                                            </h5>
                                        </div>
                                        <div className="col-3 text-end">
                                            <div className="dropstart">
                                                <a href="javascript:;" className="text-secondary" id="dropdownMarketingCard" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="fas fa-ellipsis-v"></i>
                                                </a>
                                                <ul className="dropdown-menu dropdown-menu-lg-start px-2 py-3" aria-labelledby="dropdownMarketingCard">
                                                    <li><a className="dropdown-item border-radius-md" href="javascript:;">Edit Team</a></li>
                                                    <li><a className="dropdown-item border-radius-md" href="javascript:;">Add Member</a></li>
                                                    <li><a className="dropdown-item border-radius-md" href="javascript:;">See Details</a></li>
                                                    <li>
                                                        <hr className="dropdown-divider" />
                                                    </li>
                                                    <li><a className="dropdown-item border-radius-md text-danger" href="javascript:;">Remove Team</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <p>A group of people who collectively are responsible for all of the work necessary to produce working, validated assets.</p>
                                    <ul className="list-unstyled mx-auto">
                                        <li className="d-flex">
                                            <p className="mb-0">Industry:</p>
                                            <span className="badge badge-secondary ms-auto">Marketing Team</span>
                                        </li>
                                        <li>
                                            <hr className="horizontal dark" />
                                        </li>
                                        <li className="d-flex">
                                            <p className="mb-0">Rating:</p>
                                            <div className="rating ms-auto">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star-half-alt"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <hr className="horizontal dark" />
                                        </li>
                                        <li className="d-flex">
                                            <p className="mb-0">Members:</p>
                                            <div className="avatar-group ms-auto">
                                                <a href="javascript:;" className="avatar avatar-lg avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Alexa Tompson">
                                                    <img alt="Image placeholder" src="../../../assets/img/team-1.jpg" />
                                                </a>
                                                <a href="javascript:;" className="avatar avatar-lg avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Romina Hadid">
                                                    <img alt="Image placeholder" src="../../../assets/img/team-2.jpg" />
                                                </a>
                                                <a href="javascript:;" className="avatar avatar-lg avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Alexander Smith">
                                                    <img alt="Image placeholder" src="../../../assets/img/team-3.jpg" />
                                                </a>
                                                <a href="javascript:;" className="avatar avatar-lg avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Martin Doe">
                                                    <img alt="Image placeholder" src="../../../assets/img/team-4.jpg" />
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card mt-4 mb-3">
                                <div className="card-body pb-0">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-9">
                                            <h5 className="mb-1">
                                                <a href="javascript:;">Design</a>
                                            </h5>
                                        </div>
                                        <div className="col-3 text-end">
                                            <div className="dropstart">
                                                <a href="javascript:;" className="text-secondary" id="dropdownDesignCard" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="fas fa-ellipsis-v"></i>
                                                </a>
                                                <ul className="dropdown-menu dropdown-menu-lg-start px-2 py-3" aria-labelledby="dropdownDesignCard">
                                                    <li><a className="dropdown-item border-radius-md" href="javascript:;">Edit Team</a></li>
                                                    <li><a className="dropdown-item border-radius-md" href="javascript:;">Add Member</a></li>
                                                    <li><a className="dropdown-item border-radius-md" href="javascript:;">See Details</a></li>
                                                    <li>
                                                        <hr className="dropdown-divider" />
                                                    </li>
                                                    <li><a className="dropdown-item border-radius-md text-danger" href="javascript:;">Remove Team</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <p>Because it's about motivating the doers. Because I’m here to follow my dreams and inspire other people to follow their dreams, too.</p>
                                    <ul className="list-unstyled mx-auto">
                                        <li className="d-flex">
                                            <p className="mb-0">Industry:</p>
                                            <span className="badge badge-secondary ms-auto">Design Team</span>
                                        </li>
                                        <li>
                                            <hr className="horizontal dark" />
                                        </li>
                                        <li className="d-flex">
                                            <p className="mb-0">Rating:</p>
                                            <div className="rating ms-auto">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                            </div>
                                        </li>
                                        <li>
                                            <hr className="horizontal dark" />
                                        </li>
                                        <li className="d-flex">
                                            <p className="mb-0">Members:</p>
                                            <div className="avatar-group ms-auto">
                                                <a href="javascript:;" className="avatar avatar-lg avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Martin Doe">
                                                    <img alt="Image placeholder" src="../../../assets/img/team-4.jpg" />
                                                </a>
                                                <a href="javascript:;" className="avatar avatar-lg avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Romina Hadid">
                                                    <img alt="Image placeholder" src="../../../assets/img/team-3.jpg" />
                                                </a>
                                                <a href="javascript:;" className="avatar avatar-lg avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Alexa Tompson">
                                                    <img alt="Image placeholder" src="../../../assets/img/team-1.jpg" />
                                                </a>
                                                <a href="javascript:;" className="avatar avatar-lg avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Alexandra Smith">
                                                    <img alt="Image placeholder" src="../../../assets/img/team-5.jpg" />
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card mt-4">
                                <div className="card-body p-3">
                                    <div className="d-flex">
                                        <div className="avatar avatar-lg">
                                            <img alt="Image placeholder" src="../../../assets/img/small-logos/logo-slack.svg" />
                                        </div>
                                        <div className="ms-2 my-auto">
                                            <h6 className="mb-0">Slack Meet</h6>
                                            <p className="text-xs mb-0">11:00 AM</p>
                                        </div>
                                    </div>
                                    <p className="mt-3"> You have an upcoming meet for Marketing Planning</p>
                                    <p className="mb-0"><b>Meeting ID:</b> 902-128-281</p>
                                    <hr className="horizontal dark" />
                                    <div className="d-flex">
                                        <button type="button" className="btn btn-sm btn-primary mb-0">
                                            Join
                                        </button>
                                        <div className="avatar-group ms-auto">
                                            <a href="javascript:;" className="avatar avatar-lg avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Alexa Tompson">
                                                <img alt="Image placeholder" src="../../../assets/img/team-1.jpg" />
                                            </a>
                                            <a href="javascript:;" className="avatar avatar-lg avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Romina Hadid">
                                                <img alt="Image placeholder" src="../../../assets/img/team-2.jpg" />
                                            </a>
                                            <a href="javascript:;" className="avatar avatar-lg avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Alexander Smith">
                                                <img alt="Image placeholder" src="../../../assets/img/team-3.jpg" />
                                            </a>
                                            <a href="javascript:;" className="avatar avatar-lg avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Martin Doe">
                                                <img alt="Image placeholder" src="../../../assets/img/ivana-squares.jpg" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mt-4">
                                <div className="card-body p-3">
                                    <div className="d-flex">
                                        <div className="avatar avatar-lg">
                                            <img alt="Image placeholder" src="../../../assets/img/small-logos/logo-invision.svg" />
                                        </div>
                                        <div className="ms-2 my-auto">
                                            <h6 className="mb-0">Invision</h6>
                                            <p className="text-xs mb-0">4:50 PM</p>
                                        </div>
                                    </div>
                                    <p className="mt-3"> You have an upcoming video call for <span className="text-primary">Soft Design</span> at 5:00 PM.</p>
                                    <p className="mb-0"><b>Meeting ID:</b> 111-968-981</p>
                                    <hr className="horizontal dark" />
                                    <div className="d-flex">
                                        <button type="button" className="btn btn-sm btn-primary mb-0">
                                            Join
                                        </button>
                                        <div className="avatar-group ms-auto">
                                            <a href="javascript:;" className="avatar avatar-lg avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Alexa Tompson">
                                                <img alt="Image placeholder" src="../../../assets/img/team-1.jpg" />
                                            </a>
                                            <a href="javascript:;" className="avatar avatar-lg avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Romina Hadid">
                                                <img alt="Image placeholder" src="../../../assets/img/team-2.jpg" />
                                            </a>
                                            <a href="javascript:;" className="avatar avatar-lg avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Alexander Smith">
                                                <img alt="Image placeholder" src="../../../assets/img/team-3.jpg" />
                                            </a>
                                            <a href="javascript:;" className="avatar avatar-lg avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Martin Doe">
                                                <img alt="Image placeholder" src="../../../assets/img/ivana-squares.jpg" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className="footer pt-3  ">
                        <div className="container-fluid">
                            <div className="row align-items-center justify-content-lg-between">
                                <div className="col-lg-6 mb-lg-0 mb-4">
                                    <div className="copyright text-center text-sm text-muted text-lg-start">
                                        ©
                                        <script>
                                            document.write(new Date().getFullYear())
                                        </script>, made with <i className="fa fa-heart"></i> by
                                        <a href="https://www.creative-tim.com" className="font-weight-bold" target="_blank">Creative Tim</a> for a better web.
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                                        <li className="nav-item">
                                            <a href="https://www.creative-tim.com" className="nav-link text-muted" target="_blank">Creative Tim</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="https://www.creative-tim.com/presentation" className="nav-link text-muted" target="_blank">About Us</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="https://www.creative-tim.com/blog" className="nav-link text-muted" target="_blank">Blog</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="https://www.creative-tim.com/license" className="nav-link pe-0 text-muted" target="_blank">License</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
                <FixedPlugins />
            </main>
        </Fragment>
    )
}

export default CompanyTeams