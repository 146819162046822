import React, { Fragment } from 'react'
import FixedPlugins from '../Components/FixedPlugins'
import NavBar from '../Components/NavBar'
import SideMenu from '../Components/SideMenu'

const CustomerList: React.FC = () => {
    return (
        <Fragment>
            <div className="body-bg min-height-300 bg-primary position-absolute w-100"></div>
            <SideMenu />
            <main className="main-content position-relative border-radius-lg ">
                <NavBar />
                <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">

                                <div className="card-header pb-0">
                                    <div className="d-lg-flex">
                                        <div>
                                            <h5 className="mb-0">All Products</h5>
                                            <p className="text-sm mb-0">
                                                A lightweight, extendable, dependency-free javascript HTML table plugin.
                                            </p>
                                        </div>
                                        <div className="ms-auto my-auto mt-lg-0 mt-4">
                                            <div className="ms-auto my-auto">
                                                <a href="./new-product.html" className="btn bg-gradient-primary btn-sm mb-0" target="_blank">+&nbsp; New Product</a>
                                                <button type="button" className="btn btn-outline-primary btn-sm mb-0" data-bs-toggle="modal" data-bs-target="#import">
                                                    Import
                                                </button>
                                                <div className="modal fade" id="import" tabIndex={-1} aria-hidden="true">
                                                    <div className="modal-dialog mt-lg-10">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="ModalLabel">Import CSV</h5>
                                                                <i className="fas fa-upload ms-3"></i>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <p>You can browse your computer for a file.</p>
                                                                <input type="text" placeholder="Browse file..." className="form-control mb-3" />
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" value="" id="importCheck" />
                                                                    <label className="custom-control-label" htmlFor="importCheck">I accept the terms and conditions</label>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn bg-gradient-secondary btn-sm" data-bs-dismiss="modal">Close</button>
                                                                <button type="button" className="btn bg-gradient-primary btn-sm">Upload</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button className="btn btn-outline-primary btn-sm export mb-0 mt-sm-0 mt-1" data-type="csv" type="button" name="button">Export</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body px-0 pb-0">
                                    <div className="table-responsive">
                                        <table className="table table-flush" id="products-list">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Category</th>
                                                    <th>Price</th>
                                                    <th>SKU</th>
                                                    <th>Quantity</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check my-auto">
                                                                <input className="form-check-input" type="checkbox" id="customCheck1" checked />
                                                            </div>
                                                            <img className="w-10 ms-3" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/adidas-hoodie.jpg" alt="hoodie" />
                                                            <h6 className="ms-3 my-auto">BKLGO Full Zip Hoodie</h6>
                                                        </div>
                                                    </td>
                                                    <td className="text-sm">Clothing</td>
                                                    <td className="text-sm">$1,321</td>
                                                    <td className="text-sm">243598234</td>
                                                    <td className="text-sm">0</td>
                                                    <td>
                                                        <span className="badge badge-danger badge-sm">Out of Stock</span>
                                                    </td>
                                                    <td className="text-sm">
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Preview product">
                                                            <i className="fas fa-eye text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" className="mx-3" data-bs-toggle="tooltip" data-bs-original-title="Edit product">
                                                            <i className="fas fa-user-edit text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Delete product">
                                                            <i className="fas fa-trash text-secondary"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check my-auto">
                                                                <input className="form-check-input" type="checkbox" id="customCheck2" checked />
                                                            </div>
                                                            <img className="w-10 ms-3" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/macbook-pro.jpg" alt="mac" />
                                                            <h6 className="ms-3 my-auto">MacBook Pro</h6>
                                                        </div>
                                                    </td>
                                                    <td className="text-sm">Electronics</td>
                                                    <td className="text-sm">$1,869</td>
                                                    <td className="text-sm">877712</td>
                                                    <td className="text-sm">0</td>
                                                    <td>
                                                        <span className="badge badge-danger badge-sm">Out of Stock</span>
                                                    </td>
                                                    <td className="text-sm">
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Preview product">
                                                            <i className="fas fa-eye text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" className="mx-3" data-bs-toggle="tooltip" data-bs-original-title="Edit product">
                                                            <i className="fas fa-user-edit text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Delete product">
                                                            <i className="fas fa-trash text-secondary"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check my-auto">
                                                                <input className="form-check-input" type="checkbox" id="customCheck3" />
                                                            </div>
                                                            <img className="w-10 ms-3" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/metro-chair.jpg" alt="metro-chair" />
                                                            <h6 className="ms-3 my-auto">Metro Bar Stool</h6>
                                                        </div>
                                                    </td>
                                                    <td className="text-sm">Furniture</td>
                                                    <td className="text-sm">$99</td>
                                                    <td className="text-sm">0134729</td>
                                                    <td className="text-sm">978</td>
                                                    <td>
                                                        <span className="badge badge-success badge-sm">in Stock</span>
                                                    </td>
                                                    <td className="text-sm">
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Preview product">
                                                            <i className="fas fa-eye text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" className="mx-3" data-bs-toggle="tooltip" data-bs-original-title="Edit product">
                                                            <i className="fas fa-user-edit text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Delete product">
                                                            <i className="fas fa-trash text-secondary"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check my-auto">
                                                                <input className="form-check-input" type="checkbox" id="customCheck10" />
                                                            </div>
                                                            <img className="w-10 ms-3" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/alchimia-chair.jpg" alt="alchimia" />
                                                            <h6 className="ms-3 my-auto">Alchimia Chair</h6>
                                                        </div>
                                                    </td>
                                                    <td className="text-sm">Furniture</td>
                                                    <td className="text-sm">$2,999</td>
                                                    <td className="text-sm">113213</td>
                                                    <td className="text-sm">0</td>
                                                    <td>
                                                        <span className="badge badge-danger badge-sm">Out of Stock</span>
                                                    </td>
                                                    <td className="text-sm">
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Preview product">
                                                            <i className="fas fa-eye text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" className="mx-3" data-bs-toggle="tooltip" data-bs-original-title="Edit product">
                                                            <i className="fas fa-user-edit text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Delete product">
                                                            <i className="fas fa-trash text-secondary"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check my-auto">
                                                                <input className="form-check-input" type="checkbox" id="customCheck5" />
                                                            </div>
                                                            <img className="w-10 ms-3" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/fendi-coat.jpg" alt="fendi" />
                                                            <h6 className="ms-3 my-auto">Fendi Gradient Coat</h6>
                                                        </div>
                                                    </td>
                                                    <td className="text-sm">Clothing</td>
                                                    <td className="text-sm">$869</td>
                                                    <td className="text-sm">634729</td>
                                                    <td className="text-sm">725</td>
                                                    <td>
                                                        <span className="badge badge-success badge-sm">in Stock</span>
                                                    </td>
                                                    <td className="text-sm">
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Preview product">
                                                            <i className="fas fa-eye text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" className="mx-3" data-bs-toggle="tooltip" data-bs-original-title="Edit product">
                                                            <i className="fas fa-user-edit text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Delete product">
                                                            <i className="fas fa-trash text-secondary"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check my-auto">
                                                                <input className="form-check-input" type="checkbox" id="customCheck6" />
                                                            </div>
                                                            <img className="w-10 ms-3" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/off-white-jacket.jpg" alt="off_white" />
                                                            <h6 className="ms-3 my-auto">Off White Cotton Bomber</h6>
                                                        </div>
                                                    </td>
                                                    <td className="text-sm">Clothing</td>
                                                    <td className="text-sm">$1,869</td>
                                                    <td className="text-sm">634729</td>
                                                    <td className="text-sm">725</td>
                                                    <td>
                                                        <span className="badge badge-success badge-sm">in Stock</span>
                                                    </td>
                                                    <td className="text-sm">
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Preview product">
                                                            <i className="fas fa-eye text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" className="mx-3" data-bs-toggle="tooltip" data-bs-original-title="Edit product">
                                                            <i className="fas fa-user-edit text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Delete product">
                                                            <i className="fas fa-trash text-secondary"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check my-auto">
                                                                <input className="form-check-input" type="checkbox" id="customCheck7" checked />
                                                            </div>
                                                            <img className="w-10 ms-3" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/yohji-yamamoto.jpg" alt="yohji" />
                                                            <h6 className="ms-3 my-auto">Y-3 Yohji Yamamoto</h6>
                                                        </div>
                                                    </td>
                                                    <td className="text-sm">Shoes</td>
                                                    <td className="text-sm">$869</td>
                                                    <td className="text-sm">634729</td>
                                                    <td className="text-sm">725</td>
                                                    <td>
                                                        <span className="badge badge-success badge-sm">In Stock</span>
                                                    </td>
                                                    <td className="text-sm">
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Preview product">
                                                            <i className="fas fa-eye text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" className="mx-3" data-bs-toggle="tooltip" data-bs-original-title="Edit product">
                                                            <i className="fas fa-user-edit text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Delete product">
                                                            <i className="fas fa-trash text-secondary"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check my-auto">
                                                                <input className="form-check-input" type="checkbox" id="customCheck9" checked />
                                                            </div>
                                                            <img className="w-10 ms-3" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/mcqueen-shirt.jpg" alt="mcqueen" />
                                                            <h6 className="ms-3 my-auto">Alexander McQueen</h6>
                                                        </div>
                                                    </td>
                                                    <td className="text-sm">Clothing</td>
                                                    <td className="text-sm">$1,199</td>
                                                    <td className="text-sm">00121399</td>
                                                    <td className="text-sm">51293</td>
                                                    <td>
                                                        <span className="badge badge-success badge-sm">in Stock</span>
                                                    </td>
                                                    <td className="text-sm">
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Preview product">
                                                            <i className="fas fa-eye text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" className="mx-3" data-bs-toggle="tooltip" data-bs-original-title="Edit product">
                                                            <i className="fas fa-user-edit text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Delete product">
                                                            <i className="fas fa-trash text-secondary"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check my-auto">
                                                                <input className="form-check-input" type="checkbox" id="customCheck11" checked />
                                                            </div>
                                                            <img className="w-10 ms-3" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/yellow-chair.jpg" alt="coco" />
                                                            <h6 className="ms-3 my-auto">Luin Floor Lamp</h6>
                                                        </div>
                                                    </td>
                                                    <td className="text-sm">Furniture</td>
                                                    <td className="text-sm">$1,900</td>
                                                    <td className="text-sm">434729</td>
                                                    <td className="text-sm">1100191321</td>
                                                    <td>
                                                        <span className="badge badge-success badge-sm">In Stock</span>
                                                    </td>
                                                    <td className="text-sm">
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Preview product">
                                                            <i className="fas fa-eye text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" className="mx-3" data-bs-toggle="tooltip" data-bs-original-title="Edit product">
                                                            <i className="fas fa-user-edit text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Delete product">
                                                            <i className="fas fa-trash text-secondary"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check my-auto">
                                                                <input className="form-check-input" type="checkbox" id="customCheck12" />
                                                            </div>
                                                            <img className="w-10 ms-3" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/heron-tshirt.jpg" alt="heron" />
                                                            <h6 className="ms-3 my-auto">Heron Preston T-shirt</h6>
                                                        </div>
                                                    </td>
                                                    <td className="text-sm">Clothing</td>
                                                    <td className="text-sm">$149</td>
                                                    <td className="text-sm">928341</td>
                                                    <td className="text-sm">0</td>
                                                    <td>
                                                        <span className="badge badge-danger badge-sm">Out of Stock</span>
                                                    </td>
                                                    <td className="text-sm">
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Preview product">
                                                            <i className="fas fa-eye text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" className="mx-3" data-bs-toggle="tooltip" data-bs-original-title="Edit product">
                                                            <i className="fas fa-user-edit text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Delete product">
                                                            <i className="fas fa-trash text-secondary"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check my-auto">
                                                                <input className="form-check-input" type="checkbox" id="customCheck13" checked />
                                                            </div>
                                                            <img className="w-10 ms-3" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/living-chair.jpg" alt="coco" />
                                                            <h6 className="ms-3 my-auto">Gray Living Chair</h6>
                                                        </div>
                                                    </td>
                                                    <td className="text-sm">Furniture</td>
                                                    <td className="text-sm">$2,099</td>
                                                    <td className="text-sm">9912834</td>
                                                    <td className="text-sm">32</td>
                                                    <td>
                                                        <span className="badge badge-success badge-sm">in Stock</span>
                                                    </td>
                                                    <td className="text-sm">
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Preview product">
                                                            <i className="fas fa-eye text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" className="mx-3" data-bs-toggle="tooltip" data-bs-original-title="Edit product">
                                                            <i className="fas fa-user-edit text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Delete product">
                                                            <i className="fas fa-trash text-secondary"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check my-auto">
                                                                <input className="form-check-input" type="checkbox" id="customCheck4" />
                                                            </div>
                                                            <img className="w-10 ms-3" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/orange-sofa.jpg" alt="yohji" />
                                                            <h6 className="ms-3 my-auto">Derbyshire Orange Sofa</h6>
                                                        </div>
                                                    </td>
                                                    <td className="text-sm">Furniture</td>
                                                    <td className="text-sm">$2,999</td>
                                                    <td className="text-sm">561151</td>
                                                    <td className="text-sm">22</td>
                                                    <td>
                                                        <span className="badge badge-success badge-sm">in Stock</span>
                                                    </td>
                                                    <td className="text-sm">
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Preview product">
                                                            <i className="fas fa-eye text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" className="mx-3" data-bs-toggle="tooltip" data-bs-original-title="Edit product">
                                                            <i className="fas fa-user-edit text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Delete product">
                                                            <i className="fas fa-trash text-secondary"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check my-auto">
                                                                <input className="form-check-input" type="checkbox" id="customCheck8" checked />
                                                            </div>
                                                            <img className="w-10 ms-3" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/burberry.jpg" alt="undercover" />
                                                            <h6 className="ms-3 my-auto">Burberry Low-Tops</h6>
                                                        </div>
                                                    </td>
                                                    <td className="text-sm">Shoes</td>
                                                    <td className="text-sm">$869</td>
                                                    <td className="text-sm">634729</td>
                                                    <td className="text-sm">725</td>
                                                    <td>
                                                        <span className="badge badge-success badge-sm">in Stock</span>
                                                    </td>
                                                    <td className="text-sm">
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Preview product">
                                                            <i className="fas fa-eye text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" className="mx-3" data-bs-toggle="tooltip" data-bs-original-title="Edit product">
                                                            <i className="fas fa-user-edit text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Delete product">
                                                            <i className="fas fa-trash text-secondary"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check my-auto">
                                                                <input className="form-check-input" type="checkbox" id="customCheck22" />
                                                            </div>
                                                            <img className="w-10 ms-3" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/d&g-skirt.jpg" alt="D&G" />
                                                            <h6 className="ms-3 my-auto">Dolce & Gabbana Skirt</h6>
                                                        </div>
                                                    </td>
                                                    <td className="text-sm">Designer</td>
                                                    <td className="text-sm">$999</td>
                                                    <td className="text-sm">01827391</td>
                                                    <td className="text-sm">0</td>
                                                    <td>
                                                        <span className="badge badge-danger badge-sm">Out of Stock</span>
                                                    </td>
                                                    <td className="text-sm">
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Preview product">
                                                            <i className="fas fa-eye text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" className="mx-3" data-bs-toggle="tooltip" data-bs-original-title="Edit product">
                                                            <i className="fas fa-user-edit text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Delete product">
                                                            <i className="fas fa-trash text-secondary"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div className="form-check my-auto">
                                                                <input className="form-check-input" type="checkbox" id="customCheck23" checked />
                                                            </div>
                                                            <img className="w-10 ms-3" src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/undercover.jpg" alt="undercover" />
                                                            <h6 className="ms-3 my-auto">Undercover T-shirt</h6>
                                                        </div>
                                                    </td>
                                                    <td className="text-sm">Shoes</td>
                                                    <td className="text-sm">$869</td>
                                                    <td className="text-sm">634729</td>
                                                    <td className="text-sm">725</td>
                                                    <td>
                                                        <span className="badge badge-success badge-sm">in Stock</span>
                                                    </td>
                                                    <td className="text-sm">
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Preview product">
                                                            <i className="fas fa-eye text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" className="mx-3" data-bs-toggle="tooltip" data-bs-original-title="Edit product">
                                                            <i className="fas fa-user-edit text-secondary"></i>
                                                        </a>
                                                        <a href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Delete product">
                                                            <i className="fas fa-trash text-secondary"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Category</th>
                                                    <th>Price</th>
                                                    <th>SKU</th>
                                                    <th>Quantity</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className="footer pt-3  ">
                        <div className="container-fluid">
                            <div className="row align-items-center justify-content-lg-between">
                                <div className="col-lg-6 mb-lg-0 mb-4">
                                    <div className="copyright text-center text-sm text-muted text-lg-start">
                                        ©
                                        <script>
                                            document.write(new Date().getFullYear())
                                        </script>, made with <i className="fa fa-heart"></i> by
                                        <a href="https://www.creative-tim.com" className="font-weight-bold" target="_blank">Creative Tim</a> for a better web.
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                                        <li className="nav-item">
                                            <a href="https://www.creative-tim.com" className="nav-link text-muted" target="_blank">Creative Tim</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="https://www.creative-tim.com/presentation" className="nav-link text-muted" target="_blank">About Us</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="https://www.creative-tim.com/blog" className="nav-link text-muted" target="_blank">Blog</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="https://www.creative-tim.com/license" className="nav-link pe-0 text-muted" target="_blank">License</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
                <FixedPlugins />
            </main>

        </Fragment>

    )
}

export default CustomerList