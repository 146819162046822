import React from 'react'
import { Link } from 'react-router-dom'
import { PageLinks } from '../Common/Constants'

const SideMenu: React.FC = () => {
    return (
        <aside className="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 " id="sidenav-main">
            <div className="sidenav-header">
                <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
                <Link to={PageLinks.Home}>
                    <a className="navbar-brand m-0" href=" https://demos.creative-tim.com/argon-dashboard-pro/pages/dashboards/default.html " target="_blank">
                        <img src="../../assets/img/logo-ct-dark.png" className="navbar-brand-img h-100" alt="main_logo" />
                        <span className="ms-1 font-weight-bold">AEON</span>
                    </a>
                </Link>
            </div>
            <hr className="horizontal dark mt-0" />
            <div className="collapse navbar-collapse  w-auto h-auto" id="sidenav-collapse-main">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a data-bs-toggle="collapse" href="#dashboardsExamples" className="nav-link active" aria-controls="dashboardsExamples" role="button" aria-expanded="false">
                            <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                <i className="ni ni-shop text-primary text-sm opacity-10"></i>
                            </div>
                            <span className="nav-link-text ms-1">Dashboards</span>
                        </a>
                        <div className="collapse  show " id="dashboardsExamples">
                            <ul className="nav ms-4">
                                <li className="nav-item ">
                                    <Link to={PageLinks.Home}>
                                        <a className="nav-link ">
                                            <span className="sidenav-mini-icon"> H </span>
                                            <span className="sidenav-normal"> Home </span>
                                        </a>
                                    </Link>
                                </li>
                                {/* <li className="nav-item active">
                                    <a className="nav-link active" href="../../pages/dashboards/default.html">
                                        <span className="sidenav-mini-icon"> D </span>
                                        <span className="sidenav-normal"> Default </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="../../pages/dashboards/automotive.html">
                                        <span className="sidenav-mini-icon"> A </span>
                                        <span className="sidenav-normal"> Automotive </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="../../pages/dashboards/smart-home.html">
                                        <span className="sidenav-mini-icon"> S </span>
                                        <span className="sidenav-normal"> Smart Home </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " data-bs-toggle="collapse" aria-expanded="false" href="#vrExamples">
                                        <span className="sidenav-mini-icon"> V </span>
                                        <span className="sidenav-normal"> Virtual Reality <b className="caret"></b></span>
                                    </a>
                                    <div className="collapse " id="vrExamples">
                                        <ul className="nav nav-sm flex-column">
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/dashboards/vr/vr-default.html">
                                                    <span className="sidenav-mini-icon text-xs"> V </span>
                                                    <span className="sidenav-normal"> VR Default </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/dashboards/vr/vr-info.html">
                                                    <span className="sidenav-mini-icon text-xs"> V </span>
                                                    <span className="sidenav-normal"> VR Info </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="../../pages/dashboards/crm.html">
                                        <span className="sidenav-mini-icon"> C </span>
                                        <span className="sidenav-normal"> CRM </span>
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item mt-3">
                        <h6 className="ps-4  ms-2 text-uppercase text-xs font-weight-bolder opacity-6">PAGES</h6>
                    </li>
                    <li className="nav-item">
                        <a data-bs-toggle="collapse" href="#pagesExamples" className="nav-link " aria-controls="pagesExamples" role="button" aria-expanded="false">
                            <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                <i className="ni ni-ungroup text-warning text-sm opacity-10"></i>
                            </div>
                            <span className="nav-link-text ms-1">Pages</span>
                        </a>
                        <div className="collapse " id="pagesExamples">
                            <ul className="nav ms-4">
                                <li className="nav-item ">
                                    <a className="nav-link " data-bs-toggle="collapse" aria-expanded="false" href="#profileExample">
                                        <span className="sidenav-mini-icon"> P </span>
                                        <span className="sidenav-normal"> Profile <b className="caret"></b></span>
                                    </a>
                                    <div className="collapse " id="profileExample">
                                        <ul className="nav nav-sm flex-column">
                                            <li className="nav-item">
                                                <Link to={PageLinks.CompanyProfile}>
                                                    <a className="nav-link ">
                                                        <span className="sidenav-mini-icon text-xs"> P </span>
                                                        <span className="sidenav-normal"> Profile Overview </span>
                                                    </a>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link ">
                                                    <Link to={PageLinks.CompanyTeam}>
                                                        <span className="sidenav-mini-icon text-xs"> T </span>
                                                        <span className="sidenav-normal"> Teams </span>
                                                    </Link>
                                                </a>
                                            </li>
                                            {/* <li className="nav-item">
                                                <a className="nav-link ">
                                                    <span className="sidenav-mini-icon text-xs"> A </span>
                                                    <span className="sidenav-normal"> All Projects </span>
                                                </a>
                                            </li> */}
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " data-bs-toggle="collapse" aria-expanded="false" href="#usersExample">
                                        <span className="sidenav-mini-icon"> C </span>
                                        <span className="sidenav-normal"> Customers <b className="caret"></b></span>
                                    </a>
                                    <div className="collapse " id="usersExample">
                                        <ul className="nav nav-sm flex-column">
                                            <li className="nav-item">
                                                <Link to={PageLinks.CustomerList}>
                                                    <a className="nav-link ">
                                                        <span className="sidenav-mini-icon text-xs"> C </span>
                                                        <span className="sidenav-normal"> Customer List</span>
                                                    </a>
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to={PageLinks.AddCustomer}>
                                                    <a className="nav-link ">
                                                        <span className="sidenav-mini-icon text-xs"> N </span>
                                                        <span className="sidenav-normal"> New Customer </span>
                                                    </a>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                {/* <li className="nav-item ">
                                    <a className="nav-link " data-bs-toggle="collapse" aria-expanded="false" href="#accountExample">
                                        <span className="sidenav-mini-icon"> A </span>
                                        <span className="sidenav-normal"> Account <b className="caret"></b></span>
                                    </a>
                                    <div className="collapse " id="accountExample">
                                        <ul className="nav nav-sm flex-column">
                                            <li className="nav-item">
                                                <a className="nav-link ">
                                                    <span className="sidenav-mini-icon text-xs"> S </span>
                                                    <span className="sidenav-normal"> Settings </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link ">
                                                    <span className="sidenav-mini-icon text-xs"> B </span>
                                                    <span className="sidenav-normal"> Billing </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link ">
                                                    <span className="sidenav-mini-icon text-xs"> I </span>
                                                    <span className="sidenav-normal"> Invoice </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link ">
                                                    <span className="sidenav-mini-icon text-xs"> S </span>
                                                    <span className="sidenav-normal"> Security </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " data-bs-toggle="collapse" aria-expanded="false" href="#projectsExample">
                                        <span className="sidenav-mini-icon"> P </span>
                                        <span className="sidenav-normal"> Projects <b className="caret"></b></span>
                                    </a>
                                    <div className="collapse " id="projectsExample">
                                        <ul className="nav nav-sm flex-column">
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/pages/projects/general.html">
                                                    <span className="sidenav-mini-icon text-xs"> G </span>
                                                    <span className="sidenav-normal"> General </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/pages/projects/timeline.html">
                                                    <span className="sidenav-mini-icon text-xs"> T </span>
                                                    <span className="sidenav-normal"> Timeline </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/pages/projects/new-project.html">
                                                    <span className="sidenav-mini-icon text-xs"> N </span>
                                                    <span className="sidenav-normal"> New Project </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li> 
                                <li className="nav-item ">
                                    <a className="nav-link " href="../../pages/pages/pricing-page.html">
                                        <span className="sidenav-mini-icon"> P </span>
                                        <span className="sidenav-normal"> Pricing Page </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="../../pages/pages/rtl-page.html">
                                        <span className="sidenav-mini-icon"> R </span>
                                        <span className="sidenav-normal"> RTL </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="../../pages/pages/widgets.html">
                                        <span className="sidenav-mini-icon"> W </span>
                                        <span className="sidenav-normal"> Widgets </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="../../pages/pages/charts.html">
                                        <span className="sidenav-mini-icon"> C </span>
                                        <span className="sidenav-normal"> Charts </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="../../pages/pages/sweet-alerts.html">
                                        <span className="sidenav-mini-icon"> S </span>
                                        <span className="sidenav-normal"> Sweet Alerts </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="../../pages/pages/notifications.html">
                                        <span className="sidenav-mini-icon"> N </span>
                                        <span className="sidenav-normal"> Notifications </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="../../pages/pages/chat.html">
                                        <span className="sidenav-mini-icon"> C </span>
                                        <span className="sidenav-normal"> Chat </span>
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                    </li>
                    {/* <li className="nav-item">
                        <a data-bs-toggle="collapse" href="#applicationsExamples" className="nav-link " aria-controls="applicationsExamples" role="button" aria-expanded="false">
                            <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                <i className="ni ni-ui-04 text-info text-sm opacity-10"></i>
                            </div>
                            <span className="nav-link-text ms-1">Applications</span>
                        </a>
                        <div className="collapse " id="applicationsExamples">
                            <ul className="nav ms-4">
                                <li className="nav-item ">
                                    <a className="nav-link " href="../../pages/applications/kanban.html">
                                        <span className="sidenav-mini-icon"> K </span>
                                        <span className="sidenav-normal"> Kanban </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="../../pages/applications/wizard.html">
                                        <span className="sidenav-mini-icon"> W </span>
                                        <span className="sidenav-normal"> Wizard </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="../../pages/applications/datatables.html">
                                        <span className="sidenav-mini-icon"> D </span>
                                        <span className="sidenav-normal"> DataTables </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="../../pages/applications/calendar.html">
                                        <span className="sidenav-mini-icon"> C </span>
                                        <span className="sidenav-normal"> Calendar </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="../../pages/applications/analytics.html">
                                        <span className="sidenav-mini-icon"> A </span>
                                        <span className="sidenav-normal"> Analytics </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a data-bs-toggle="collapse" href="#ecommerceExamples" className="nav-link " aria-controls="ecommerceExamples" role="button" aria-expanded="false">
                            <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                <i className="ni ni-archive-2 text-success text-sm opacity-10"></i>
                            </div>
                            <span className="nav-link-text ms-1">Ecommerce</span>
                        </a>
                        <div className="collapse " id="ecommerceExamples">
                            <ul className="nav ms-4">
                                <li className="nav-item ">
                                    <a className="nav-link " href="../../pages/ecommerce/overview.html">
                                        <span className="sidenav-mini-icon"> O </span>
                                        <span className="sidenav-normal"> Overview </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " data-bs-toggle="collapse" aria-expanded="false" href="#productsExample">
                                        <span className="sidenav-mini-icon"> P </span>
                                        <span className="sidenav-normal"> Products <b className="caret"></b></span>
                                    </a>
                                    <div className="collapse " id="productsExample">
                                        <ul className="nav nav-sm flex-column">
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/ecommerce/products/new-product.html">
                                                    <span className="sidenav-mini-icon text-xs"> N </span>
                                                    <span className="sidenav-normal"> New Product </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/ecommerce/products/edit-product.html">
                                                    <span className="sidenav-mini-icon text-xs"> E </span>
                                                    <span className="sidenav-normal"> Edit Product </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/ecommerce/products/product-page.html">
                                                    <span className="sidenav-mini-icon text-xs"> P </span>
                                                    <span className="sidenav-normal"> Product Page </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/ecommerce/products/products-list.html">
                                                    <span className="sidenav-mini-icon text-xs"> P </span>
                                                    <span className="sidenav-normal"> Products List </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " data-bs-toggle="collapse" aria-expanded="false" href="#ordersExample">
                                        <span className="sidenav-mini-icon"> O </span>
                                        <span className="sidenav-normal"> Orders <b className="caret"></b></span>
                                    </a>
                                    <div className="collapse " id="ordersExample">
                                        <ul className="nav nav-sm flex-column">
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/ecommerce/orders/list.html">
                                                    <span className="sidenav-mini-icon text-xs"> O </span>
                                                    <span className="sidenav-normal"> Order List </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/ecommerce/orders/details.html">
                                                    <span className="sidenav-mini-icon text-xs"> O </span>
                                                    <span className="sidenav-normal"> Order Details </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="../../pages/ecommerce/referral.html">
                                        <span className="sidenav-mini-icon"> R </span>
                                        <span className="sidenav-normal"> Referral </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a data-bs-toggle="collapse" href="#authExamples" className="nav-link " aria-controls="authExamples" role="button" aria-expanded="false">
                            <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                <i className="ni ni-single-copy-04 text-danger text-sm opacity-10"></i>
                            </div>
                            <span className="nav-link-text ms-1">Authentication</span>
                        </a>
                        <div className="collapse " id="authExamples">
                            <ul className="nav ms-4">
                                <li className="nav-item ">
                                    <a className="nav-link " data-bs-toggle="collapse" aria-expanded="false" href="#signinExample">
                                        <span className="sidenav-mini-icon"> S </span>
                                        <span className="sidenav-normal"> Sign In <b className="caret"></b></span>
                                    </a>
                                    <div className="collapse " id="signinExample">
                                        <ul className="nav nav-sm flex-column">
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/authentication/signin/basic.html">
                                                    <span className="sidenav-mini-icon text-xs"> B </span>
                                                    <span className="sidenav-normal"> Basic </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/authentication/signin/cover.html">
                                                    <span className="sidenav-mini-icon text-xs"> C </span>
                                                    <span className="sidenav-normal"> Cover </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/authentication/signin/illustration.html">
                                                    <span className="sidenav-mini-icon text-xs"> I </span>
                                                    <span className="sidenav-normal"> Illustration </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " data-bs-toggle="collapse" aria-expanded="false" href="#signupExample">
                                        <span className="sidenav-mini-icon"> S </span>
                                        <span className="sidenav-normal"> Sign Up <b className="caret"></b></span>
                                    </a>
                                    <div className="collapse " id="signupExample">
                                        <ul className="nav nav-sm flex-column">
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/authentication/signup/basic.html">
                                                    <span className="sidenav-mini-icon text-xs"> B </span>
                                                    <span className="sidenav-normal"> Basic </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/authentication/signup/cover.html">
                                                    <span className="sidenav-mini-icon text-xs"> C </span>
                                                    <span className="sidenav-normal"> Cover </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/authentication/signup/illustration.html">
                                                    <span className="sidenav-mini-icon text-xs"> I </span>
                                                    <span className="sidenav-normal"> Illustration </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " data-bs-toggle="collapse" aria-expanded="false" href="#resetExample">
                                        <span className="sidenav-mini-icon"> R </span>
                                        <span className="sidenav-normal"> Reset Password <b className="caret"></b></span>
                                    </a>
                                    <div className="collapse " id="resetExample">
                                        <ul className="nav nav-sm flex-column">
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/authentication/reset/basic.html">
                                                    <span className="sidenav-mini-icon text-xs"> B </span>
                                                    <span className="sidenav-normal"> Basic </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/authentication/reset/cover.html">
                                                    <span className="sidenav-mini-icon text-xs"> C </span>
                                                    <span className="sidenav-normal"> Cover </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/authentication/reset/illustration.html">
                                                    <span className="sidenav-mini-icon text-xs"> I </span>
                                                    <span className="sidenav-normal"> Illustration </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " data-bs-toggle="collapse" aria-expanded="false" href="#lockExample">
                                        <span className="sidenav-mini-icon"> L </span>
                                        <span className="sidenav-normal"> Lock <b className="caret"></b></span>
                                    </a>
                                    <div className="collapse " id="lockExample">
                                        <ul className="nav nav-sm flex-column">
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/authentication/lock/basic.html">
                                                    <span className="sidenav-mini-icon text-xs"> B </span>
                                                    <span className="sidenav-normal"> Basic </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/authentication/lock/cover.html">
                                                    <span className="sidenav-mini-icon text-xs"> C </span>
                                                    <span className="sidenav-normal"> Cover </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/authentication/lock/illustration.html">
                                                    <span className="sidenav-mini-icon text-xs"> I </span>
                                                    <span className="sidenav-normal"> Illustration </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " data-bs-toggle="collapse" aria-expanded="false" href="#StepExample">
                                        <span className="sidenav-mini-icon"> 2 </span>
                                        <span className="sidenav-normal"> 2-Step Verification <b className="caret"></b></span>
                                    </a>
                                    <div className="collapse " id="StepExample">
                                        <ul className="nav nav-sm flex-column">
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/authentication/verification/basic.html">
                                                    <span className="sidenav-mini-icon text-xs"> B </span>
                                                    <span className="sidenav-normal"> Basic </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/authentication/verification/cover.html">
                                                    <span className="sidenav-mini-icon text-xs"> C </span>
                                                    <span className="sidenav-normal"> Cover </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/authentication/verification/illustration.html">
                                                    <span className="sidenav-mini-icon text-xs"> I </span>
                                                    <span className="sidenav-normal"> Illustration </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " data-bs-toggle="collapse" aria-expanded="false" href="#errorExample">
                                        <span className="sidenav-mini-icon"> E </span>
                                        <span className="sidenav-normal"> Error <b className="caret"></b></span>
                                    </a>
                                    <div className="collapse " id="errorExample">
                                        <ul className="nav nav-sm flex-column">
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/authentication/error/404.html">
                                                    <span className="sidenav-mini-icon text-xs"> E </span>
                                                    <span className="sidenav-normal"> Error 404 </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="../../pages/authentication/error/500.html">
                                                    <span className="sidenav-mini-icon text-xs"> E </span>
                                                    <span className="sidenav-normal"> Error 500 </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li> */}
                    {/* <li className="nav-item">
                        <hr className="horizontal dark" />
                        <h6 className="ps-4  ms-2 text-uppercase text-xs font-weight-bolder opacity-6">DOCS</h6>
                    </li>
                    <li className="nav-item">
                        <a data-bs-toggle="collapse" href="#basicExamples" className="nav-link " aria-controls="basicExamples" role="button" aria-expanded="false">
                            <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                <i className="ni ni-spaceship text-dark text-sm"></i>
                            </div>
                            <span className="nav-link-text ms-1">Basic</span>
                        </a>
                        <div className="collapse " id="basicExamples">
                            <ul className="nav ms-4">
                                <li className="nav-item ">
                                    <a className="nav-link " data-bs-toggle="collapse" aria-expanded="false" href="#gettingStartedExample">
                                        <span className="sidenav-mini-icon"> G </span>
                                        <span className="sidenav-normal"> Getting Started <b className="caret"></b></span>
                                    </a>
                                    <div className="collapse " id="gettingStartedExample">
                                        <ul className="nav nav-sm flex-column">
                                            <li className="nav-item">
                                                <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/quick-start/argon-dashboard" target="_blank">
                                                    <span className="sidenav-mini-icon text-xs"> Q </span>
                                                    <span className="sidenav-normal"> Quick Start </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/license/argon-dashboard" target="_blank">
                                                    <span className="sidenav-mini-icon text-xs"> L </span>
                                                    <span className="sidenav-normal"> License </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/overview/argon-dashboard" target="_blank">
                                                    <span className="sidenav-mini-icon text-xs"> C </span>
                                                    <span className="sidenav-normal"> Contents </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/build-tools/argon-dashboard" target="_blank">
                                                    <span className="sidenav-mini-icon text-xs"> B </span>
                                                    <span className="sidenav-normal"> Build Tools </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " data-bs-toggle="collapse" aria-expanded="false" href="#foundationExample">
                                        <span className="sidenav-mini-icon"> F </span>
                                        <span className="sidenav-normal"> Foundation <b className="caret"></b></span>
                                    </a>
                                    <div className="collapse " id="foundationExample">
                                        <ul className="nav nav-sm flex-column">
                                            <li className="nav-item">
                                                <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/colors/argon-dashboard" target="_blank">
                                                    <span className="sidenav-mini-icon text-xs"> C </span>
                                                    <span className="sidenav-normal"> Colors </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/grid/argon-dashboard" target="_blank">
                                                    <span className="sidenav-mini-icon text-xs"> G </span>
                                                    <span className="sidenav-normal"> Grid </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/typography/argon-dashboard" target="_blank">
                                                    <span className="sidenav-mini-icon text-xs"> T </span>
                                                    <span className="sidenav-normal"> Typography </span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/icons/argon-dashboard" target="_blank">
                                                    <span className="sidenav-mini-icon text-xs"> I </span>
                                                    <span className="sidenav-normal"> Icons </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a data-bs-toggle="collapse" href="#componentsExamples" className="nav-link " aria-controls="componentsExamples" role="button" aria-expanded="false">
                            <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                <i className="ni ni-app text-dark text-sm"></i>
                            </div>
                            <span className="nav-link-text ms-1">Components</span>
                        </a>
                        <div className="collapse " id="componentsExamples">
                            <ul className="nav ms-4">
                                <li className="nav-item ">
                                    <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/alerts/argon-dashboard" target="_blank">
                                        <span className="sidenav-mini-icon"> A </span>
                                        <span className="sidenav-normal"> Alerts </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/badge/argon-dashboard" target="_blank">
                                        <span className="sidenav-mini-icon"> B </span>
                                        <span className="sidenav-normal"> Badge </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/buttons/argon-dashboard" target="_blank">
                                        <span className="sidenav-mini-icon"> B </span>
                                        <span className="sidenav-normal"> Buttons </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/cards/argon-dashboard" target="_blank">
                                        <span className="sidenav-mini-icon"> C </span>
                                        <span className="sidenav-normal"> Card </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/carousel/argon-dashboard" target="_blank">
                                        <span className="sidenav-mini-icon"> C </span>
                                        <span className="sidenav-normal"> Carousel </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/collapse/argon-dashboard" target="_blank">
                                        <span className="sidenav-mini-icon"> C </span>
                                        <span className="sidenav-normal"> Collapse </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/dropdowns/argon-dashboard" target="_blank">
                                        <span className="sidenav-mini-icon"> D </span>
                                        <span className="sidenav-normal"> Dropdowns </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/forms/argon-dashboard" target="_blank">
                                        <span className="sidenav-mini-icon"> F </span>
                                        <span className="sidenav-normal"> Forms </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/modal/argon-dashboard" target="_blank">
                                        <span className="sidenav-mini-icon"> M </span>
                                        <span className="sidenav-normal"> Modal </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/navs/argon-dashboard" target="_blank">
                                        <span className="sidenav-mini-icon"> N </span>
                                        <span className="sidenav-normal"> Navs </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/navbar/argon-dashboard" target="_blank">
                                        <span className="sidenav-mini-icon"> N </span>
                                        <span className="sidenav-normal"> Navbar </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/pagination/argon-dashboard" target="_blank">
                                        <span className="sidenav-mini-icon"> P </span>
                                        <span className="sidenav-normal"> Pagination </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/popovers/argon-dashboard" target="_blank">
                                        <span className="sidenav-mini-icon"> P </span>
                                        <span className="sidenav-normal"> Popovers </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/progress/argon-dashboard" target="_blank">
                                        <span className="sidenav-mini-icon"> P </span>
                                        <span className="sidenav-normal"> Progress </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/spinners/argon-dashboard" target="_blank">
                                        <span className="sidenav-mini-icon"> S </span>
                                        <span className="sidenav-normal"> Spinners </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/tables/argon-dashboard" target="_blank">
                                        <span className="sidenav-mini-icon"> T </span>
                                        <span className="sidenav-normal"> Tables </span>
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <a className="nav-link " href="https://www.creative-tim.com/learning-lab/bootstrap/tooltips/argon-dashboard" target="_blank">
                                        <span className="sidenav-mini-icon"> T </span>
                                        <span className="sidenav-normal"> Tooltips </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="https://github.com/creativetimofficial/ct-argon-dashboard-pro/blob/main/CHANGELOG.md" target="_blank">
                            <div className="icon icon-shape icon-sm text-center  me-2 d-flex align-items-center justify-content-center">
                                <i className="ni ni-align-left-2 text-dark text-sm"></i>
                            </div>
                            <span className="nav-link-text ms-1">Changelog</span>
                        </a>
                    </li> */}
                </ul>
            </div>
            {/* <div className="sidenav-footer mx-3 my-3">
                <div className="card card-plain shadow-none" id="sidenavCard">
                    <img className="w-60 mx-auto" src="../../assets/img/illustrations/icon-documentation.svg" alt="sidebar_illustration" />
                    <div className="card-body text-center p-3 w-100 pt-0">
                        <div className="docs-info">
                            <h6 className="mb-0">Need help?</h6>
                            <p className="text-xs font-weight-bold mb-0">Please check our docs</p>
                        </div>
                    </div>
                </div>
                <a href="https://www.creative-tim.com/learning-lab/bootstrap/overview/argon-dashboard" target="_blank" className="btn btn-dark btn-sm w-100 mb-3">Documentation</a>
            </div> */}
        </aside >
    )
}

export default SideMenu